import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import ScheduleCalendar from './Calendar';

const CalendarWrapper = () => (
  <Switch>
    <Route exact path={ROUTES?.CALENDAR} component={ScheduleCalendar} />
  </Switch>
);

export default CalendarWrapper;
