import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import Post from './Post';
import ViewPost from './ViewPost';
import './create.less';

const PostWrapper = () => (
  <Switch>
    <Route exact path={ROUTES?.POST} component={Post} />
    <Route exact path={`${ROUTES?.POST}/:postId/edit`} component={Post} />
    <Route exact path={`${ROUTES?.POST}/:postId/view`} component={ViewPost} />
  </Switch>
);

export default PostWrapper;
