import {
  DeleteOutlined,
  PlusOutlined,
  UnlockOutlined,
} from '@ant-design/icons';
import { Avatar, Button, Card, Col, Modal, Row, Space, Typography } from 'antd';
import dayjs from 'dayjs';
import React, { useContext, useState } from 'react';
import { AppContext } from '../../../AppContext';
import ImagePlaceholder from '../../../assets/images/placeholder.jpg';
import SocialPostIllustration from '../../../assets/svg/addSocialPost.svg';
import {
  FACEBOOK_KEYS,
  NETWORK,
  REAUTHENTICATION,
  SIZE,
  defaultDateFormat,
} from '../../../common/constants';
import LoaderComponent from '../../../components/LoaderComponent';

const { Title } = Typography;

function Facebook({ mutation, deleteMutation, data, loading }) {
  const { updateNetwork } = useContext(AppContext);
  // eslint-disable-next-line no-undef
  const network = localStorage?.getItem(NETWORK);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const addFacebookPage = () => {
    mutation({
      variables: {
        data: { network: FACEBOOK_KEYS?.FACEBOOKPAGE },
      },
    });
    updateNetwork(FACEBOOK_KEYS?.FACEBOOKPAGE);
  };

  // Commented for future use.
  // const addFacebookGroup = () => {
  //   mutation({
  //     variables: {
  //       data: { network: FACEBOOK_KEYS?.FACEBOOKGROUP },
  //     },
  //   });
  //   updateNetwork(FACEBOOK_KEYS?.FACEBOOKGROUP);
  // };

  const deleteAccount = (accountId) => {
    deleteMutation({
      variables: {
        data: { accountId },
      },
    });
    setSelectedAccount(null);
  };

  const reAuthAccount = () => {
    // eslint-disable-next-line no-undef
    localStorage?.setItem(REAUTHENTICATION, true);
    mutation({
      variables: {
        data: { network },
      },
    });
  };
  if (loading) return <LoaderComponent />;
  return (
    <>
      <Card>
        <>
          {!data ? (
            <>
              <div className="add-social-post-wrapper">
                <img src={SocialPostIllustration} alt="" />
                <Title className="site-page-header p-0 mb-8 mt-0" level={4}>
                  Facebook Account
                </Title>
                <p>connect your facebook account and add page and group</p>
                <div className="add-btn">
                  <Space direction="vertical">
                    <Space direction="horizontal">
                      <Button
                        type="primary"
                        icon={<PlusOutlined />}
                        onClick={addFacebookPage}
                      >
                        Add Page
                      </Button>
                      {/* Commented for future use. */}
                      {/* <Button
                        icon={<PlusOutlined />}
                        onClick={addFacebookGroup}
                      >
                        Add Group
                      </Button> */}
                    </Space>
                  </Space>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="profiles">
                <Row gutter={[16, 24]}>
                  {data?.map((accountData) => (
                    <div className="profile-card" key={accountData?.id}>
                      <Col xl={4} md={12} xs={24}>
                        <Card
                          actions={[
                            <>
                              <Space direction="horizontal">
                                <Button
                                  type="primary"
                                  icon={<UnlockOutlined />}
                                  onClick={reAuthAccount}
                                >
                                  Re-Auth
                                </Button>
                                <Button
                                  danger
                                  type="link"
                                  icon={<DeleteOutlined />}
                                  onClick={() =>
                                    setSelectedAccount(accountData)
                                  }
                                >
                                  Remove
                                </Button>
                              </Space>
                            </>,
                          ]}
                        >
                          <Card.Meta
                            avatar={
                              <Avatar
                                size={SIZE}
                                src={accountData?.avatar || ImagePlaceholder}
                              />
                            }
                            title={accountData?.descriptor}
                            description={
                              <>
                                <p className="m-0">
                                  {accountData?.platformType?.toLowerCase()}
                                </p>
                                <p className="m-0">
                                  {dayjs(accountData?.createdAt)?.format(
                                    defaultDateFormat,
                                  )}
                                </p>
                              </>
                            }
                          />
                        </Card>
                      </Col>
                    </div>
                  ))}
                </Row>
              </div>
            </>
          )}
          <div className="delete-account-modal">
            <Modal
              centered
              closable={false}
              open={!!selectedAccount}
              onOk={() => deleteAccount(selectedAccount?.id)}
              onCancel={() => setSelectedAccount(null)}
            >
              <p className="m-0">
                {`Are you sure you want to remove your ${selectedAccount?.platformType?.toLowerCase()}?`}
              </p>
            </Modal>
          </div>
        </>
      </Card>
    </>
  );
}

export default Facebook;
