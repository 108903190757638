import {
  DeleteOutlined,
  EyeFilled,
  EyeInvisibleFilled,
  PlusOutlined,
  SaveOutlined,
} from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Breadcrumb,
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Upload,
} from 'antd';
import { replace, uniq } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../AppContext';
import { ROUTES } from '../../common/constants';
import { fileUpload, formValidatorRules } from '../../common/utils';
import { GET_SIGNED_URL } from '../../components/graphql/Queries';
import history from '../../historyData';
import {
  CHANGE_PASSWORD,
  DELETE_PROFILE,
  UPDATE_PROFILE,
} from './graphql/Mutation';
import './profile.less';

const { required, name } = formValidatorRules;
const moment = require('moment-timezone');

const timeZones = moment.tz.names();
const userTimeZone = moment.tz.guess(true);
const offsetTmz = [];

function Profile() {
  const { dispatch, initializeAuth, getToken, getCurrentUser } = useContext(
    AppContext,
  );
  const {
    firstName = '',
    lastName = '',
    displayName = '',
    email = '',
    avatar = '',
    timeZone = '',
    authProvider = '',
  } = getCurrentUser() || {};

  const [modalOpen, setModalOpen] = useState();
  const [btnLoading, setBtnLoading] = useState(false);
  const [fileList, setFileList] = useState(
    avatar ? [{ url: avatar?.url, name: avatar?.key }] : [],
  );
  const [form] = Form?.useForm();
  const [passwordForm] = Form?.useForm();
  const idToken = getToken();
  const [isChangePassword, setIsChangePassword] = useState(false);

  const [updateProfile] = useMutation(UPDATE_PROFILE, {
    onCompleted: (res) => {
      dispatch({
        data: res?.updateProfile?.data,
      });
      initializeAuth(idToken, res?.updateProfile?.data);
    },
    onError: () => {
      setBtnLoading(false);
    },
  });
  const [deleteProfile] = useMutation(DELETE_PROFILE, {
    onCompleted: () => {
      history?.replace(ROUTES?.LOGOUT);
    },
  });
  const [changePassword, { loading }] = useMutation(CHANGE_PASSWORD, {
    onCompleted: () => {
      passwordForm?.resetFields();
      setIsChangePassword(!isChangePassword);
    },
  });

  const [fetchSignedUrl] = useLazyQuery(GET_SIGNED_URL, {
    fetchPolicy: 'network-only',
    onError() {},
  });

  const handleUploadImage = async (info) => {
    const imageName = info?.file?.name;
    const fileName = replace(imageName, new RegExp(' ', 'g'), '_');
    const contentType = info?.file?.type;
    try {
      return fetchSignedUrl({
        variables: {
          data: {
            fileName,
            contentType,
          },
        },
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('Error while uploading image', error);
    }
  };

  useEffect(() => {
    if (displayName?.length) {
      form?.setFieldsValue({
        firstName: displayName?.split(' ', 1),
        lastName: displayName?.split(' ')?.splice(-1),
        email,
      });
    } else {
      form?.setFieldsValue({
        firstName,
        lastName,
        email,
        timeZone: `${userTimeZone} (GMT${moment
          ?.tz(userTimeZone)
          ?.format('Z')})`,
      });
    }
  }, []);

  const handleSave = () => {
    form?.submit();
  };
  const handleChangedPassword = () => {
    passwordForm?.submit();
  };

  const handleShowPrompt = () => {
    dispatch({
      type: 'SET_SHOW_PROMPT',
      data: true,
    });
  };

  const onFinish = async (values) => {
    setBtnLoading(true);
    dispatch({
      type: 'SET_SHOW_PROMPT',
      data: false,
    });
    try {
      const dataToSend = {
        ...values,
      };
      let key;
      if (values?.imageUrl) {
        if (values?.imageUrl?.fileList?.length > 0) {
          await handleUploadImage(values?.imageUrl).then(async (res) => {
            const signedImageUrl = res?.data?.getSignedPutUrl?.data?.signedUrl;
            key = res?.data?.getSignedPutUrl?.data?.key;
            await fileUpload(
              signedImageUrl,
              values?.imageUrl?.fileList?.[0]?.originFileObj,
            );
            dataToSend.imageUrl = signedImageUrl;
          });
        } else if (values?.imageUrl?.file?.status === 'removed') {
          dataToSend.imageUrl = null;
        } else {
          delete dataToSend.imageUrl;
        }
      }
      const userObj = {
        firstName: values?.firstName?.trim(),
        lastName: values?.lastName?.trim(),
        avatar: key || avatar?.key,
        timeZone: values?.timeZone,
      };
      await updateProfile({
        variables: {
          data: userObj,
        },
      });
      setBtnLoading(false);
    } catch (error) {
      setBtnLoading(false);
    }
  };

  const onActionFinish = async (values) => {
    await changePassword({
      variables: {
        data: {
          newPassword: values?.confirmPassword,
        },
      },
    });
  };

  // eslint-disable-next-line no-restricted-syntax, guard-for-in
  for (const i in timeZones) {
    offsetTmz?.push(
      `${timeZones[i]} (GMT${moment?.tz(timeZones[i])?.format('Z')}) `,
    );
  }
  const timezone = uniq(offsetTmz)?.map((str, index) => ({
    key: index,
    value: str,
  }));

  return (
    <>
      <div className="profile-setting">
        <div className="card-body-wrapper">
          <Breadcrumb className="pl-20">
            <Breadcrumb.Item>User Profile</Breadcrumb.Item>
            {!isChangePassword ? (
              <Breadcrumb.Item>Details</Breadcrumb.Item>
            ) : (
              <Breadcrumb.Item>Change Password</Breadcrumb.Item>
            )}
          </Breadcrumb>
          {!isChangePassword && (
            <Form
              form={form}
              className="sticky-action-form"
              onFieldsChange={handleShowPrompt}
              layout="vertical"
              onFinish={onFinish}
            >
              <Row>
                <Col sm={24} md={24} lg={14}>
                  <div className="profile-pic">
                    <Form.Item name="imageUrl">
                      <Upload
                        accept="image/x-png, image/jpeg, image/jpg"
                        beforeUpload={() => false}
                        className="upload-image-container"
                        listType="picture-circle"
                        showUploadList={{
                          showPreviewIcon: false,
                          showRemoveIcon: authProvider !== 'GOOGLE',
                        }}
                        onChange={(info) => {
                          setFileList(info?.fileList);
                        }}
                        fileList={fileList}
                        maxCount={1}
                      >
                        {fileList?.length === 0 && (
                          <div className="d-flex align-center justify-center flex-vertical upload-content">
                            <PlusOutlined />
                            <p>Upload Image</p>
                          </div>
                        )}
                      </Upload>
                    </Form.Item>
                  </div>
                </Col>
                <Col sm={24} md={24} lg={14}>
                  <Row gutter={[32, 4]}>
                    <Col sm={24} md={24} lg={12}>
                      <Form.Item
                        name="firstName"
                        label="First Name"
                        rules={[
                          { ...required, message: 'Please Enter First Name' },
                          name,
                        ]}
                      >
                        <Input placeholder="Enter First Name" />
                      </Form.Item>
                    </Col>
                    <Col sm={24} md={24} lg={12}>
                      <Form.Item
                        name="lastName"
                        label="Last Name"
                        rules={[
                          { ...required, message: 'Please Enter Last Name' },
                          name,
                        ]}
                      >
                        <Input placeholder="Enter Last Name" />
                      </Form.Item>
                    </Col>
                    <Col sm={24} md={24} lg={12}>
                      <Form.Item name="email" label="Email">
                        <Input disabled />
                      </Form.Item>
                    </Col>
                    <Col sm={24} md={24} lg={12}>
                      <Form.Item name="timeZone" label="Timezone">
                        <Select
                          value={timeZone}
                          showSearch
                          placeholder="Please select"
                          options={timezone}
                        />
                      </Form.Item>
                    </Col>
                    <Col sm={24} md={24} lg={12}>
                      <Space>
                        <Button
                          type="primary"
                          disabled={btnLoading}
                          onClick={handleSave}
                        >
                          <SaveOutlined /> Save
                        </Button>
                        <Button
                          type="primary"
                          danger
                          onClick={() => setModalOpen(true)}
                        >
                          <DeleteOutlined /> Delete Account
                        </Button>
                        {authProvider !== 'GOOGLE' && (
                          <Button
                            type="link"
                            onClick={() => {
                              setIsChangePassword(!isChangePassword);
                              history?.push(ROUTES?.PROFILE);
                            }}
                          >
                            Change Password
                          </Button>
                        )}
                        <div className="delete-account-modal">
                          <Modal
                            centered
                            closable={false}
                            open={modalOpen}
                            onOk={deleteProfile}
                            onCancel={() => setModalOpen(false)}
                          >
                            <p>
                              Are you sure you want to delete your account? This
                              action cannot be undone and all your data will be
                              permanently deleted.
                            </p>
                          </Modal>
                        </div>
                      </Space>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          )}
          {isChangePassword && (
            <Form
              form={passwordForm}
              className="sticky-action-form"
              onFieldsChange={handleShowPrompt}
              layout="vertical"
              onFinish={onActionFinish}
            >
              <Col sm={24} md={24} lg={8}>
                <Row gutter={[32, 4]}>
                  <Col sm={24} md={24} lg={24}>
                    <Form.Item name="password" label="New Password">
                      <Input.Password
                        placeholder="New Password"
                        iconRender={(visible) =>
                          visible ? <EyeFilled /> : <EyeInvisibleFilled />
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col sm={24} md={24} lg={24}>
                    <Form.Item
                      name="confirmPassword"
                      label="Confirm Password"
                      rules={[
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (value !== getFieldValue('password')) {
                              return Promise?.reject(
                                new Error('Password does not match'),
                              );
                            }
                            return Promise?.resolve();
                          },
                        }),
                      ]}
                    >
                      <Input.Password
                        placeholder="Confirm Password"
                        rules={[
                          { ...required, message: 'Please Enter First Name' },
                        ]}
                        iconRender={(visible) =>
                          visible ? <EyeFilled /> : <EyeInvisibleFilled />
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col sm={24} md={24} lg={24}>
                    <div className="save-password-btn">
                      <Button
                        type="primary"
                        confirmLoading={loading}
                        onClick={handleChangedPassword}
                      >
                        Save Password
                      </Button>
                      <Button
                        type="link"
                        onClick={() => {
                          setIsChangePassword(!isChangePassword);
                          history?.push(ROUTES?.PROFILE);
                        }}
                      >
                        Edit Profile
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Form>
          )}
        </div>
      </div>
    </>
  );
}
export default Profile;
