import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const POST_DETAILS = gql`
  query postDetails($where: UpdatePostWhereUniqueInput!) {
    postDetails(where: $where) {
      post {
        id
        accountId
        userId
        description
        textFormatted
        isPost
        isReel
        isStory
        link
        postId
        status
        scheduledAt
        createdAt
        updatedAt
        postIds
        faceBookCarouselAssets {
          id
          location {
            key
            url
          }
          linkedInAssets
          type
        }
        assets {
          id
          location {
            url
            key
          }
          linkedInAssets
          type
        }
        postType
        accounts {
          id
          platformId
          platform
          avatar
          descriptor
          profileUrl
        }
        accountsData {
          id
          platformId
          platform
          avatar
          descriptor
          profileUrl
        }
        postIdsWithAccountId {
          accountId
          id
          postLink
        }
      }
    }
  }
`;
