import { gql } from '@apollo/client';

export const GET_SIGNED_URL = gql`
  query getSignedPutUrl($data: SignedUrlDataInput) {
    getSignedPutUrl(data: $data) {
      data {
        signedUrl
        key
      }
    }
  }
`;

export const GET_PUBLIC_SIGNED_URL = gql`
  query getPublicSignedPutUrl($data: SignedUrlDataInput) {
    getPublicSignedPutUrl(data: $data) {
      data {
        signedUrl
        key
      }
    }
  }
`;
