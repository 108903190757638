import {
  DeleteOutlined,
  PlusOutlined,
  UnlockOutlined,
} from '@ant-design/icons';
import { Avatar, Button, Card, Col, Modal, Row, Space, Typography } from 'antd';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import ImagePlaceholder from '../../../assets/images/placeholder.jpg';
import SocialPostIllustration from '../../../assets/svg/addSocialPost.svg';
import {
  MEDIA_NAME,
  REAUTHENTICATION,
  SIZE,
  defaultDateFormat,
} from '../../../common/constants';
import LoaderComponent from '../../../components/LoaderComponent';

const { Title } = Typography;

function Instagram({ mutation, deleteMutation, data, loading }) {
  const [selectedAccount, setSelectedAccount] = useState(null);

  const addInstagram = () => {
    mutation({
      variables: {
        data: { network: MEDIA_NAME?.INSTAGRAM },
      },
    });
  };
  const deleteAccount = (accountId) => {
    deleteMutation({
      variables: {
        data: { accountId },
      },
    });
    setSelectedAccount(null);
  };
  const reAuthAccount = () => {
    // eslint-disable-next-line no-undef
    localStorage?.setItem(REAUTHENTICATION, true);
    mutation({
      variables: {
        data: { network: MEDIA_NAME?.INSTAGRAM },
      },
    });
  };
  if (loading) return <LoaderComponent />;
  return (
    <>
      <Card>
        {!data ? (
          <>
            <div className="add-social-post-wrapper">
              <img src={SocialPostIllustration} alt="" />
              <Title className="site-page-header p-0 mb-8 mt-0" level={4}>
                Instagram Account
              </Title>
              <p>connect your instagram account</p>
              <div className="add-btn">
                <Space direction="vertical">
                  <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={addInstagram}
                  >
                    Add account
                  </Button>
                </Space>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="profiles">
              <Row gutter={[16, 24]}>
                {data?.map((accountData) => (
                  <div className="profile-card" key={accountData?.id}>
                    <Col xl={4} md={12} xs={24}>
                      <Card
                        actions={[
                          <>
                            <Space direction="horizontal">
                              <Button
                                type="primary"
                                icon={<UnlockOutlined />}
                                onClick={reAuthAccount}
                              >
                                Re-Auth
                              </Button>
                              <Button
                                danger
                                type="link"
                                icon={<DeleteOutlined />}
                                onClick={() => setSelectedAccount(accountData)}
                              >
                                Remove
                              </Button>
                            </Space>
                          </>,
                        ]}
                      >
                        <Card.Meta
                          avatar={
                            <Avatar
                              size={SIZE}
                              src={accountData?.profileUrl || ImagePlaceholder}
                            />
                          }
                          title={accountData?.descriptor}
                          description={
                            <>
                              <p className="m-0">
                                {accountData?.platformType?.toLowerCase()}
                              </p>
                              <p className="m-0">
                                {dayjs(accountData?.createdAt)?.format(
                                  defaultDateFormat,
                                )}
                              </p>
                            </>
                          }
                        />
                      </Card>
                    </Col>
                  </div>
                ))}
              </Row>
            </div>
          </>
        )}
        <div className="delete-account-modal">
          <Modal
            centered
            closable={false}
            open={!!selectedAccount}
            onOk={() => deleteAccount(selectedAccount?.id)}
            onCancel={() => setSelectedAccount(null)}
          >
            <p className="m-0">
              {`Are you sure you want to remove your ${selectedAccount?.platformType?.toLowerCase()} account?`}
            </p>
          </Modal>
        </div>
      </Card>
    </>
  );
}

export default Instagram;
