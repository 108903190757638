import {
  DeleteOutlined,
  EditOutlined,
  FilterFilled,
  InfoCircleOutlined,
  ReloadOutlined,
} from '@ant-design/icons';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  Button,
  Card,
  Checkbox,
  DatePicker,
  Image,
  Modal,
  Popover,
  Radio,
  Space,
  Tag,
  Tree,
  Typography,
  message,
} from 'antd';
import dayjs from 'dayjs';
import { capitalize, filter, groupBy, includes, isEmpty, map } from 'lodash';
import React, { useEffect, useState } from 'react';
import facebookIconOrg from '../../assets/images/facebook.png';
import imagePlaceHolder from '../../assets/images/imagePlaceholder.png';
import instagramIconOrg from '../../assets/images/instagram.png';
import linkedInIconOrg from '../../assets/images/linkedin.png';
import videoPlaceHolder from '../../assets/images/videoPlaceholder.png';
import {
  CUSTOM_DATE_TIME_FORMAT,
  EDITABLE_POST_TYPE,
  MEDIA_NAME,
  MEDIA_STATUS,
  MEDIA_STATUS_OPTION,
  MEDIA_TYPE_OPTION,
  POST_STATUS_COLOR,
  POST_TYPE,
  RANGE_PRESET,
  ROUTES,
  defaultDateFormat,
} from '../../common/constants';
import CommonTable from '../../components/CommonTable';
import history from '../../historyData';
import { GET_ACCOUNTS } from '../accounts/graphql/Queries';
import { UPDATE_POST_V2 } from '../post/graphql/Mutation';
import { REMOVE_MULTIPLE_POST, REMOVE_POST } from './graphql/Mutation';
import { LIST_POST_V2 } from './graphql/Queries';

const { Title } = Typography;
const { RangePicker } = DatePicker;

function Library() {
  const [filters, setFilters] = useState({ accountIds: [], platforms: [] });
  const [removePostId, setRemovePostId] = useState();
  const [isDelete, setIsDelete] = useState();
  const [postId, setPostId] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [checkedKeys, setCheckedKeys] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [isPlatformDelete, setIsPlatformDelete] = useState(false);
  const [filterVisible, setFilterVisible] = useState({
    accountFilter: false,
    mediaStatusFilter: false,
    mediaTypeFilter: false,
    dateFilter: false,
    accountIds: false,
  });
  const [activeFilter, setActiveFilter] = useState({
    accountFilter: false,
    mediaStatusFilter: false,
    mediaTypeFilter: false,
    dateFilter: false,
    accountIds: false,
  });

  const { data: accountData } = useQuery(GET_ACCOUNTS, {
    fetchPolicy: 'network-only',
    onError() {},
  });

  const [listPost, { data, loading }] = useLazyQuery(LIST_POST_V2, {
    fetchPolicy: 'network-only',
    onError() {},
  });

  const [updatePost] = useMutation(UPDATE_POST_V2, {
    fetchPolicy: 'network-only',
    onCompleted() {
      listPost({
        variables: {
          filter: filters,
        },
        fetchPolicy: 'network-only',
      });
      setModalOpen(false);
    },
    onError() {},
  });

  const [removePost, { loading: deleteLoading }] = useMutation(REMOVE_POST, {
    onCompleted() {
      setRemovePostId(null);
      listPost({
        variables: {
          filter: filters,
        },
        fetchPolicy: 'network-only',
      });
      setModalOpen(false);
    },
    onError() {},
  });

  const [removeMultiplePost, { loading: deleteMultipleLoading }] = useMutation(
    REMOVE_MULTIPLE_POST,
    {
      onCompleted() {
        setSelectedRowKeys([]);
        listPost({
          variables: {
            filter: filters,
          },
          fetchPolicy: 'network-only',
        });
        setModalOpen(false);
      },
      onError() {},
    },
  );

  useEffect(() => {
    listPost({
      variables: {
        filter: filters,
      },
    });
  }, [filters]);

  const onChange = (pagination) => {
    setCurrentPage(pagination?.current);
    setFilters({
      ...filters,
      skip: currentPage ? (pagination?.current - 1) * pagination?.pageSize : 0,
      limit: currentPage ? pagination?.pageSize : 50,
    });
  };

  const onCheckboxChange = (checkedValues) => {
    setIsPlatformDelete(checkedValues?.includes('isPlatformDelete'));
  };

  const manageModalMessage = () => {
    if (isDelete) {
      return 'Are you sure you want to permanently delete this post from our platform?';
    }
    if (removePostId) {
      return (
        <>
          Are you sure you want to permanently delete this post from our
          platform?
          <Checkbox.Group
            options={[
              {
                label: 'Delete from social account as well!',
                value: 'isPlatformDelete',
              },
            ]}
            onChange={onCheckboxChange}
          />
        </>
      );
    }
    if (selectedRowKeys?.length > 1) {
      return (
        <>
          Are you sure you want to delete all the posts together? This action
          cannot be undone and will delete all the selected posts, including the
          published ones. <br />
          <br />
          Please deselect the post which you wish to keep. Also, please select
          the checkbox(es) below to indicate your preference for deleting
          published posts:
          <Checkbox.Group
            options={[
              {
                label: 'Delete published posts from social account as well!',
                value: 'isPlatformDelete',
              },
            ]}
            onChange={onCheckboxChange}
          />
        </>
      );
    }
  };

  useEffect(() => {
    if (removePostId) {
      setModalOpen(true);
    }
  }, [removePostId]);

  useEffect(() => {
    if (!modalOpen) {
      setIsDelete(false);
    }
  }, [modalOpen]);

  const deletePost = () => {
    if (removePostId) {
      removePost({
        variables: {
          isPlatformDelete,
          removePostId,
        },
      });
    }
    if (isDelete) {
      updatePost({
        variables: {
          where: {
            ids: postId,
          },
          data: {
            isDelete,
          },
        },
      });
    }
    if (!isEmpty(selectedRowKeys)) {
      removeMultiplePost({
        variables: {
          data: {
            isPlatformDelete,
            ids: selectedRowKeys,
          },
        },
      });
    }
  };

  const handleImageAndVideo = (record) => {
    if (includes([POST_TYPE?.IMAGE, POST_TYPE?.CAROUSEL], record?.postType)) {
      return (
        <Image
          preview={false}
          src={
            record?.assets?.[0]?.type === 'IMAGE'
              ? record?.assets?.[0]?.location?.url || imagePlaceHolder
              : videoPlaceHolder
          }
          width="100px"
        />
      );
    }
    if (record?.postType === POST_TYPE?.VIDEO) {
      return <Image preview={false} src={videoPlaceHolder} width="100px" />;
    }
    return '-';
  };

  const ReadMoreContent = ({ text, maxChar }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleReadMore = () => {
      setIsExpanded(!isExpanded);
    };

    return (
      <div className="library-description">
        {isExpanded ? (
          <div>
            {text}
            <span
              onClick={(e) => {
                e?.stopPropagation();
                toggleReadMore();
              }}
              className="show-more text-primary mt-4 pointer inline-block"
            >
              Show Less
            </span>
          </div>
        ) : (
          <div>
            {text?.slice(0, maxChar)}
            {text?.length > maxChar && (
              <span
                onClick={(e) => {
                  e?.stopPropagation();
                  toggleReadMore();
                }}
                className="show-more text-primary mt-4 pointer inline-block"
              >
                ...Show More
              </span>
            )}
          </div>
        )}
      </div>
    );
  };

  const handleClick = (record) => {
    history?.push(`${ROUTES?.POST}/${record?.ids?.[0]}/view`);
  };

  const getPlatformIconImage = (type) => {
    switch (type) {
      case MEDIA_NAME?.FACEBOOK:
        return facebookIconOrg;
      case MEDIA_NAME?.INSTAGRAM:
        return instagramIconOrg;
      default:
        return linkedInIconOrg;
    }
  };

  const treeData = map(
    groupBy(accountData?.listAccounts?.data, (account) => account?.platform),
    (groupOfPlatform, platform) => ({
      title: (
        <div className="select-media-icons">
          <>
            <Image preview={false} src={getPlatformIconImage(platform)} />
            {capitalize(platform)}
          </>
        </div>
      ),
      key: platform,
      children: groupOfPlatform.map((account) => ({
        title: account?.descriptor,
        key: account?.id,
        'data-platform': account?.platform,
      })),
    }),
  );

  const handleAccountFilter = (checkedKeysValue, e) => {
    setCheckedKeys(checkedKeysValue);
    const platforms = [];
    const accountIds = [];
    e?.checkedNodes?.filter(
      (platform) =>
        Object.keys(platform)?.includes('children') &&
        platforms?.push(platform.key),
    );
    filter(
      e?.checkedNodes,
      (platform) =>
        platform['data-platform'] &&
        !includes(platforms, platform['data-platform']) &&
        accountIds?.push(platform?.key),
    );
    setFilters({
      ...filters,
      skip: 0,
      platforms,
      accountIds,
    });
    setActiveFilter({
      ...activeFilter,
      accountFilter: true,
    });
  };

  const handleMediaStatus = (e) => {
    setCurrentPage(1);
    setFilters({
      ...filters,
      skip: 0,
      mediaStatus: e?.target?.value === 'all' ? null : e?.target?.value,
    });
    setFilterVisible({
      ...filterVisible,
      mediaStatusFilter: false,
    });
    setActiveFilter({
      ...activeFilter,
      mediaStatusFilter: true,
    });
  };

  const handleMediaType = (e) => {
    setCurrentPage(1);
    setFilters({
      ...filters,
      skip: 0,
      postType: e?.target?.value === 'all' ? null : e?.target?.value,
    });
    setFilterVisible({
      ...filterVisible,
      mediaTypeFilter: false,
    });
    setActiveFilter({
      ...activeFilter,
      mediaTypeFilter: true,
    });
  };

  const handleDatePicker = (date) => {
    setCurrentPage(1);
    setFilters({
      ...filters,
      skip: 0,
      startDate:
        date &&
        dayjs(date?.[0])?.startOf('day')?.format(CUSTOM_DATE_TIME_FORMAT),
      endDate:
        date && dayjs(date?.[1])?.endOf('day')?.format(CUSTOM_DATE_TIME_FORMAT),
    });
    setFilterVisible({
      ...filterVisible,
      dateFilter: false,
    });
    setActiveFilter({
      ...activeFilter,
      dateFilter: true,
    });
  };

  const getAccountFilterProps = () => ({
    filterDropdownOpen: filterVisible?.accountFilter,
    onFilterDropdownOpenChange: (visible) =>
      setFilterVisible({
        ...filterVisible,
        accountFilter: visible,
      }),
    filterDropdown: () => (
      <div className="columnFilter">
        <Tree
          checkable
          onCheck={handleAccountFilter}
          checkedKeys={checkedKeys}
          treeData={treeData}
        />
      </div>
    ),
    filterIcon: () => (
      <FilterFilled
        className={activeFilter?.accountFilter ? 'filter-filled-data' : ''}
      />
    ),
  });

  const getMediaStatusFilterProps = () => ({
    filterDropdownOpen: filterVisible?.mediaStatusFilter,
    onFilterDropdownOpenChange: (visible) =>
      setFilterVisible({
        ...filterVisible,
        mediaStatusFilter: visible,
      }),
    filterSearch: true,
    filterDropdown: () => (
      <div className="columnFilter">
        <Space direction="vertical">
          <Radio.Group
            defaultValue={MEDIA_STATUS_OPTION[0]?.value}
            onChange={handleMediaStatus}
          >
            <Space direction="vertical">
              {MEDIA_STATUS_OPTION?.map((option) => (
                <Radio key={option?.value} value={option?.value}>
                  {option?.label}
                </Radio>
              ))}
            </Space>
          </Radio.Group>
        </Space>
      </div>
    ),
    filterIcon: () => (
      <FilterFilled
        className={activeFilter?.mediaStatusFilter ? 'filter-filled-data' : ''}
      />
    ),
  });

  const getMediaTypeFilterProps = () => ({
    filterDropdownOpen: filterVisible?.mediaTypeFilter,
    onFilterDropdownOpenChange: (visible) =>
      setFilterVisible({
        ...filterVisible,
        mediaTypeFilter: visible,
      }),
    filterSearch: true,
    filterDropdown: () => (
      <div className="columnFilter">
        <Space direction="vertical">
          <Radio.Group
            defaultValue={MEDIA_TYPE_OPTION[0]?.value}
            onChange={handleMediaType}
          >
            <Space direction="vertical">
              {MEDIA_TYPE_OPTION?.map((option) => (
                <Radio key={option?.value} value={option?.value}>
                  {option?.label}
                </Radio>
              ))}
            </Space>
          </Radio.Group>
        </Space>
      </div>
    ),
    filterIcon: () => (
      <FilterFilled
        className={activeFilter?.mediaTypeFilter ? 'filter-filled-data' : ''}
      />
    ),
  });

  const getDateFilterProps = () => ({
    filterDropdownOpen: filterVisible?.dateFilter,
    onFilterDropdownOpenChange: (visible) =>
      setFilterVisible({
        ...filterVisible,
        dateFilter: visible,
      }),
    filterSearch: true,
    filterDropdown: () => (
      <div className="columnFilter">
        <Space direction="vertical">
          <RangePicker
            allowClear
            open={filterVisible?.dateFilter}
            presets={RANGE_PRESET}
            onChange={handleDatePicker}
          />
        </Space>
      </div>
    ),
    filterIcon: () => (
      <FilterFilled
        className={activeFilter?.dateFilter ? 'filter-filled-data' : ''}
      />
    ),
  });

  const columns = [
    {
      title: 'Social Account',
      dataIndex: 'account',
      key: 'account',
      width: 140,
      align: 'center',
      render: (val, record) =>
        record?.accounts.map((account) => (
          <div className="select-media-icons" key={account?.id}>
            <Image
              preview={false}
              src={getPlatformIconImage(account?.platform)}
            />
            {account?.descriptor}
          </div>
        )),
      ...getAccountFilterProps(),
    },
    {
      title: 'Media',
      dataIndex: 'media',
      key: 'media',
      children: [
        {
          title: 'Image / Video',
          dataIndex: 'image',
          key: 'image',
          width: 120,
          align: 'center',
          render: (text, record) => handleImageAndVideo(record),
        },
        {
          title: 'Description',
          dataIndex: 'description',
          key: 'description',
          width: 300,
          render: (text) =>
            text ? <ReadMoreContent text={text} maxChar={30} /> : '-',
        },
      ],
    },
    {
      title: 'Media Type',
      dataIndex: 'postType',
      key: 'postType',
      width: 80,
      align: 'center',
      render: (text) => text || '-',
      ...getMediaTypeFilterProps(),
    },
    {
      title: 'Media Status',
      dataIndex: 'status',
      key: 'status',
      width: 120,
      align: 'center',
      render: (val, record) => {
        const status = groupBy(record?.postStatusWithAccountId, 'status');
        return Object.values(status)?.map((item) => (
          <>
            <Space className="status-cell-content">
              <Popover
                content={item?.map((account) => (
                  <div className="select-media-icons" key={account?.id}>
                    <Image
                      preview={false}
                      src={getPlatformIconImage(account?.platform)}
                    />
                    {account?.descriptor}
                  </div>
                ))}
              >
                <Tag color={POST_STATUS_COLOR?.[item?.[0]?.status]}>
                  {item?.[0]?.status} ({item?.length})
                  <InfoCircleOutlined />
                </Tag>
              </Popover>
            </Space>
          </>
        ));
      },
      ...getMediaStatusFilterProps(),
    },
    {
      title: 'Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 140,
      align: 'center',
      ...getDateFilterProps(),
      render: (text) => dayjs(text)?.format(`${defaultDateFormat} hh:mm A`),
    },
    {
      title: 'Scheduled At',
      dataIndex: 'scheduledAt',
      key: 'scheduledAt',
      width: 140,
      align: 'center',
      render: (text) =>
        text ? dayjs(text)?.format(`${defaultDateFormat} hh:mm A`) : '-',
    },
    {
      title: 'Action',
      key: 'action',
      align: 'center',
      width: 120,
      render: (val, record) => (
        <div className="table-col-action">
          <Space size="middle" align="center">
            <Button
              type="link"
              disabled={!includes(EDITABLE_POST_TYPE, record?.status)}
              onClick={(e) => {
                e?.stopPropagation();
                if (record?.ids?.length > 0) {
                  history?.push(`${ROUTES?.POST}/${record?.ids?.[0]}/edit`);
                } else {
                  message.error('This post is not editable');
                }
              }}
            >
              {record?.status === MEDIA_STATUS.FAILED ? (
                <ReloadOutlined />
              ) : (
                <EditOutlined />
              )}
            </Button>
            <div
              className={
                includes(MEDIA_STATUS?.PUBLISHED, record?.status) &&
                (record?.postType === POST_TYPE?.CAROUSEL ||
                  record?.accounts?.filter(
                    (account) => account?.platform === MEDIA_NAME?.INSTAGRAM,
                  )?.length > 0)
                  ? 'delete-action-icon-disable'
                  : 'delete-action-icon'
              }
            >
              <Button
                type="link"
                disabled={
                  includes(MEDIA_STATUS?.PUBLISHED, record?.status) &&
                  record?.postType === POST_TYPE?.CAROUSEL
                }
                onClick={(e) => {
                  e?.stopPropagation();
                  if (
                    includes(
                      [MEDIA_STATUS?.DRAFT, MEDIA_STATUS?.SCHEDULED],
                      record?.status,
                    )
                  ) {
                    setPostId(record?.ids);
                    setIsDelete(true);
                  } else {
                    setRemovePostId(record?.commonPostId);
                  }
                  setModalOpen(true);
                }}
              >
                <DeleteOutlined />
              </Button>
            </div>
          </Space>
        </div>
      ),
    },
  ];

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const deleteAllPost = () => {
    setModalOpen(true);
  };

  return (
    <>
      <div className="library">
        <Title className="site-page-header p-0 mb-8 mt-0" level={3}>
          Library
          {selectedRowKeys?.length > 1 && (
            <Button danger onClick={deleteAllPost}>
              <DeleteOutlined />
              Delete All
            </Button>
          )}
        </Title>
        <div className="table-card-page">
          <Card className="ant-body-scroll">
            <div className="card-body-wrapper">
              <CommonTable
                size="small"
                columns={columns}
                className="pointer"
                dataSource={data?.listPostsV2?.posts}
                loading={loading}
                rowKey={(record) => record?.commonPostId}
                rowSelection={{
                  selectedRowKeys,
                  onChange: onSelectChange,
                }}
                onRow={(record) => ({ onClick: () => handleClick(record) })}
                bordered
                onChange={onChange}
                paginationConfig={{
                  current: currentPage,
                  total: data?.listPostsV2?.count,
                }}
                scroll={{
                  y: 630,
                }}
              />
            </div>
          </Card>
        </div>
      </div>
      <Modal
        centered
        closable={false}
        open={modalOpen}
        onOk={deletePost}
        confirmLoading={deleteLoading || deleteMultipleLoading}
        onCancel={() => setModalOpen(false)}
        className="checkbox-modal"
      >
        <p className="m-0">{manageModalMessage()}</p>
      </Modal>
    </>
  );
}

export default Library;
