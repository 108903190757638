import {
  ExclamationCircleOutlined,
  FieldTimeOutlined,
  FileImageOutlined,
  FontSizeOutlined,
  FormOutlined,
  LinkOutlined,
  ScheduleOutlined,
  VideoCameraOutlined,
} from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import { Image, Popover, Select, Space, Typography, message } from 'antd';
import dayjs from 'dayjs';
import { includes } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { Calendar, dayjsLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { useHistory } from 'react-router-dom';
import CarouselIcon from '../../assets/images/carouselIcon.png';
import facebookIconOrg from '../../assets/images/facebook.png';
import instagramIconOrg from '../../assets/images/instagram.png';
import linkedInIconOrg from '../../assets/images/linkedin.png';
import {
  CALENDAR_MODULE,
  EDITABLE_POST_TYPE,
  MEDIA_NAME,
  MEDIA_STATUS,
  MEDIA_STATUS_OPTION,
  POST_TYPE,
  ROUTES,
  SOCIAL_MEDIA,
  timeFormat,
} from '../../common/constants';
import LoaderComponent from '../../components/LoaderComponent';
import { LIST_POST_V2 } from '../library/graphql/Queries';
import './calendar.less';

const { Title } = Typography;
const localizer = dayjsLocalizer(dayjs);

function ScheduleCalendar() {
  const history = useHistory();
  const [platformName, setPlatformName] = useState([SOCIAL_MEDIA[0]?.value]);
  const [filters, setFilters] = useState({
    accountIds: [],
    platforms: [],
  });
  const events = [];
  const schedule = true;

  const [listPost, { data: postData, loading }] = useLazyQuery(LIST_POST_V2, {
    fetchPolicy: 'network-only',
    onError() {},
  });

  useEffect(() => {
    listPost({
      variables: {
        filter: {
          ...filters,
          module: CALENDAR_MODULE,
        },
      },
    });
  }, [filters]);

  const handleMediaStatus = (value) => {
    setFilters({ ...filters, mediaStatus: value === 'all' ? null : value });
  };

  const handleClick = (event) => {
    const id = event?.title?.props?.id;
    if (event?.title?.props?.name === 'edit-post') {
      history?.push(`${ROUTES?.POST}/${id}/edit`);
    }
    if (event?.title?.props?.name === 'view-post') {
      history?.push(`${ROUTES?.POST}/${id}/view`);
    }
  };

  const iconForMediaStatus = (status) => {
    if (status === MEDIA_STATUS?.SCHEDULED) {
      return <FieldTimeOutlined />;
    }
    if (status === MEDIA_STATUS?.DRAFT) {
      return <FormOutlined />;
    }
    if (status === MEDIA_STATUS?.FAILED) {
      return <ExclamationCircleOutlined />;
    }
    return <ScheduleOutlined />;
  };

  const iconForPostType = (postType) => {
    if (postType === POST_TYPE?.IMAGE) {
      return <FileImageOutlined />;
    }
    if (postType === POST_TYPE?.VIDEO) {
      return <VideoCameraOutlined />;
    }
    if (postType === POST_TYPE?.TEXT) {
      return <FontSizeOutlined />;
    }
    if (postType === POST_TYPE?.CAROUSEL) {
      return (
        <div className="event-image-icon">
          <Image preview={false} src={CarouselIcon} />
        </div>
      );
    }
    return <LinkOutlined />;
  };

  const getPlatformIconImage = (type) => {
    switch (type) {
      case MEDIA_NAME?.FACEBOOK:
        return facebookIconOrg;
      case MEDIA_NAME?.INSTAGRAM:
        return instagramIconOrg;
      default:
        return linkedInIconOrg;
    }
  };

  const fetchEventData = (post) => (
    <Popover
      id={post?.ids?.[0]}
      data={post}
      className="calendar-event-content"
      name={
        includes(EDITABLE_POST_TYPE, post?.status) ? 'edit-post' : 'view-post'
      }
      content={post?.accounts?.map((account) => (
        <>
          <div className="select-media-icons">
            <Image
              preview={false}
              src={getPlatformIconImage(account?.platform)}
            />
            {account?.descriptor}
          </div>
        </>
      ))}
    >
      <span>
        {dayjs(post?.scheduledAt ?? post?.createdAt)?.format(timeFormat)}
      </span>
      <span>{iconForMediaStatus(post?.status)}</span>
      <span>{iconForPostType(post?.postType)}</span>
    </Popover>
  );

  postData?.listPostsV2?.posts?.map((post) =>
    events?.push({
      title: fetchEventData(post),
      start: new Date(post?.scheduledAt ?? post?.createdAt),
      end: new Date(post?.scheduledAt ?? post?.createdAt),
    }),
  );

  const handleSelect = ({ start, end }) => {
    const startTime = dayjs(start);
    const endTime = dayjs(end);
    if (
      !endTime.isAfter(dayjs(), 'day') &&
      (endTime.isBefore(dayjs(), 'day') ||
        startTime.hour() < dayjs().hour() ||
        (startTime.hour() === dayjs().hour() &&
          dayjs().minute() >= endTime.minute()))
    ) {
      message?.error('Please select future time to schedule a post');
    } else {
      history?.push({ pathname: ROUTES?.POST, data: { start, schedule } });
    }
  };

  const slotPropGetter = (day) => {
    const time = dayjs(day);
    const isSameHour = time.hour() === dayjs().hour();
    if (
      (time.isBefore(dayjs(), 'day') ||
        time.hour() < dayjs().hour() ||
        (isSameHour && dayjs().minute() >= time.minute())) &&
      !time.isAfter(dayjs(), 'day')
    ) {
      return { className: 'past-dates' };
    }
  };

  const dayPropGetter = useCallback(
    (day) => ({
      ...(dayjs(day).isBefore(dayjs(), 'day') && {
        className: 'past-dates',
      }),
    }),
    [],
  );

  const handleSocialOption = (value) => {
    setPlatformName(value);
    setFilters({ ...filters, platforms: value === 'ALL' ? [] : [value] });
  };

  return (
    <>
      <Title className="site-page-header p-0 mb-8 mt-0" level={3}>
        <div
          className="calendar-page-header"
          onKeyDown={(e) => e.stopPropagation()}
        >
          Calendar
          <Space>
            <div className="social-name-selector">
              <Select
                value={platformName}
                placeholder="Select social platform"
                options={SOCIAL_MEDIA}
                onChange={handleSocialOption}
              />
              <Select
                placeholder="Select Status"
                onChange={handleMediaStatus}
                value={filters?.mediaStatus || MEDIA_STATUS_OPTION[0]?.value}
                options={MEDIA_STATUS_OPTION}
              />
            </div>
          </Space>
        </div>
      </Title>
      <div className="calendar-card">
        {loading && <LoaderComponent />}
        <Calendar
          views={['day', 'week', 'month']}
          selectable
          localizer={localizer}
          defaultDate={new Date()}
          popup
          dayPropGetter={dayPropGetter}
          slotPropGetter={slotPropGetter}
          tooltipAccessor={false}
          onSelectEvent={(event) => handleClick(event)}
          allDayMaxRows={2}
          defaultView="month"
          events={events}
          onSelectSlot={(e) => handleSelect(e)}
        />
      </div>
    </>
  );
}

export default ScheduleCalendar;
