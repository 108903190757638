import { Carousel, Image } from 'antd';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import ImagePlaceholder from '../../../assets/images/imagePlaceholder.png';
import {
  InstaComment,
  InstaLike,
  InstaSave,
  InstaShare,
} from '../../../assets/svg';
import { videoViewer } from '../../../common/utils';
import '../create.less';

const InstagramLayout = (props) => {
  const {
    instagramUserName,
    description,
    videoSource,
    avatarViewer,
    postData,
    headerImageUrl,
    instagramAvatar,
  } = props;
  const ReadMoreContent = ({ text, maxChar }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleReadMore = () => {
      setIsExpanded(!isExpanded);
    };

    return (
      <div className="description">
        {isExpanded ? (
          <div>
            {text}
            <span
              onClick={(e) => {
                e?.stopPropagation();
                toggleReadMore();
              }}
              className="show-more text-primary mt-4 pointer inline-block"
            >
              Show Less
            </span>
          </div>
        ) : (
          <div>
            {text?.slice(0, maxChar)}
            {text?.length > maxChar && (
              <span
                onClick={(e) => {
                  e?.stopPropagation();
                  toggleReadMore();
                }}
                className="show-more text-primary mt-4 pointer inline-block"
              >
                ...Show More
              </span>
            )}
          </div>
        )}
      </div>
    );
  };
  return (
    <div className="post">
      <div className="post-header">
        {avatarViewer(instagramAvatar, instagramUserName)}
        <div className="post-info">
          <h2>{instagramUserName}</h2>
          <p>
            {dayjs(postData?.postDetails?.post?.createdAt)?.format('DD MMMM')}
          </p>
        </div>
      </div>
      <div className="post-image">
        <Carousel autoplay>
          {headerImageUrl?.length > 0 &&
            headerImageUrl.map((img) => (
              <div key={img?.name}>
                <Image preview={false} src={img?.url || ImagePlaceholder} />
              </div>
            ))}
          {videoSource?.length > 0 &&
            videoSource.map((video) => (
              <div key={video?.name} className="post-video">
                {videoViewer(video, videoSource)}
              </div>
            ))}
        </Carousel>
      </div>
      <div className="insta-post-footer">
        <>
          <div className="footer-icons">
            <InstaLike />
            <InstaComment />
            <InstaShare />
          </div>
        </>
        <>
          <div className="footer-icons">
            <div className="save-icon">
              <InstaSave />
            </div>
          </div>
        </>
      </div>
      <div className="post-body">
        <ReadMoreContent
          text={`${instagramUserName} ${description ? `:${description}` : ''}`}
          maxChar={170}
        />
      </div>
    </div>
  );
};

export default InstagramLayout;
