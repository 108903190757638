import {
  ExclamationCircleOutlined,
  FieldTimeOutlined,
  FileImageOutlined,
  FontSizeOutlined,
  FormOutlined,
  LinkOutlined,
  ScheduleOutlined,
  VideoCameraOutlined,
} from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import { Image, Layout, Tooltip } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useMedia } from 'react-use';
import { AppContext } from '../AppContext';
import {
  BREAKPOINTS,
  EMAIL_VERIFIED,
  MEDIA_STATUS,
  POST_TYPE,
  ROUTES,
} from '../common/constants';
import MobileLogoComponent from '../components/MobileLogoComponent';
import RouterPrompt from '../components/RouterPrompt';
import './App.css';
import ContentRoutes from './ContentRoutes';
import AppHeader from './components/header/AppHeader';
import UserProfile from './components/header/UserProfile';
import Sidebar from './components/sidebar/Sidebar';

import CarouselIcon from '../assets/images/carouselIcon.png';
import LoaderComponent from '../components/LoaderComponent';
import history from '../historyData';
import { GET_ACCOUNTS } from '../modules/accounts/graphql/Queries';

const { Content, Sider } = Layout;

const App = () => {
  // eslint-disable-next-line no-undef, no-unused-vars
  const isVerified = JSON?.parse(localStorage?.getItem(EMAIL_VERIFIED));
  const { state } = useContext(AppContext);
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);
  const [isActive, setActive] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const path = history?.location?.pathname || ROUTES?.MAIN;

  const { data, loading } = useQuery(GET_ACCOUNTS, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (isVerified) {
      history?.replace(path);
      // eslint-disable-next-line no-undef
      localStorage?.removeItem(EMAIL_VERIFIED);
    } else {
      history?.push(ROUTES?.EMAIL_VERIFICATION);
    }
  }, []);

  const handleOverlay = () => {
    setActive(!isActive);
  };
  const location = useLocation();

  return (
    <Layout hasSider>
      <RouterPrompt openPrompt={state?.showPrompt} />
      {!isDesktopViewport && (
        <span
          className={isActive ? 'active overlay-responsive' : 'overlay-disable'}
          onClick={handleOverlay}
        />
      )}
      <Sider
        collapsible
        theme="light"
        collapsed={collapsed || !isDesktopViewport}
        onCollapse={(value) => setCollapsed(value)}
        className={isActive ? 'close' : null}
        breakpoint={BREAKPOINTS?.mobile}
      >
        <Link id="logo" to={ROUTES?.MAIN}>
          {collapsed || !isDesktopViewport ? (
            <MobileLogoComponent className="mr-0" />
          ) : (
            <>
              <MobileLogoComponent className="mr-8" />
              Share Hive
            </>
          )}
        </Link>
        <Sidebar />
        {location.pathname === ROUTES.CALENDAR && (
          <div className="calander-note">
            <h4>Notes:</h4>
            {collapsed || !isDesktopViewport ? (
              <ul>
                <li>
                  <Tooltip placement="right" title={MEDIA_STATUS?.PUBLISHED}>
                    <ScheduleOutlined />
                  </Tooltip>
                </li>
                <li>
                  <Tooltip placement="right" title={MEDIA_STATUS?.SCHEDULED}>
                    <FieldTimeOutlined />
                  </Tooltip>
                </li>
                <li>
                  <Tooltip placement="right" title={MEDIA_STATUS?.DRAFT}>
                    <FormOutlined />
                  </Tooltip>
                </li>
                <li>
                  <Tooltip placement="right" title={MEDIA_STATUS?.FAILED}>
                    <ExclamationCircleOutlined />
                  </Tooltip>
                </li>
                <li>
                  <Tooltip placement="right" title={POST_TYPE?.TEXT}>
                    <FontSizeOutlined />
                  </Tooltip>
                </li>
                <li>
                  <Tooltip placement="right" title={POST_TYPE?.IMAGE}>
                    <FileImageOutlined />
                  </Tooltip>
                </li>
                <li>
                  <Tooltip placement="right" title={POST_TYPE?.VIDEO}>
                    <VideoCameraOutlined />
                  </Tooltip>
                </li>
                <li>
                  <Tooltip placement="right" title={POST_TYPE?.LINK}>
                    <LinkOutlined />
                  </Tooltip>
                </li>
                <li>
                  <Tooltip placement="right" title={POST_TYPE?.CAROUSEL}>
                    <Image
                      preview={false}
                      src={CarouselIcon}
                      width="14px"
                      height="14px"
                    />
                  </Tooltip>
                </li>
              </ul>
            ) : (
              <>
                <ul>
                  <li>
                    <ScheduleOutlined />
                    {MEDIA_STATUS?.PUBLISHED}
                  </li>
                  <li>
                    <FieldTimeOutlined />
                    {MEDIA_STATUS?.SCHEDULED}
                  </li>
                  <li>
                    <FormOutlined />

                    {MEDIA_STATUS?.DRAFT}
                  </li>
                  <li>
                    <ExclamationCircleOutlined />

                    {MEDIA_STATUS?.FAILED}
                  </li>
                  <li>
                    <FontSizeOutlined />
                    {POST_TYPE?.TEXT}
                  </li>
                  <li>
                    <FileImageOutlined />
                    {POST_TYPE?.IMAGE}
                  </li>
                  <li>
                    <VideoCameraOutlined />
                    {POST_TYPE?.VIDEO}
                  </li>
                  <li>
                    <LinkOutlined />
                    {POST_TYPE?.LINK}
                  </li>
                  <li>
                    <Image
                      preview={false}
                      src={CarouselIcon}
                      width="14px"
                      height="14px"
                    />
                    {POST_TYPE?.CAROUSEL}
                  </li>
                </ul>
              </>
            )}
          </div>
        )}
      </Sider>
      <Layout className="site-layout">
        <AppHeader>
          <div className="header-wrapper">
            <a
              href="https://sharehive.app/privacy-policy"
              target="_blank"
              rel="noreferrer"
            >
              <u>Privacy Policy</u>
            </a>
            <UserProfile />
          </div>
        </AppHeader>
        <Content className="wrapper">
          {loading ? (
            <LoaderComponent />
          ) : (
            <ContentRoutes isAccountAdded={data?.listAccounts?.data.length} />
          )}
        </Content>
      </Layout>
    </Layout>
  );
};

export default App;
