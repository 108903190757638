import { DownOutlined, PlusOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Avatar,
  Button,
  Card,
  Col,
  Dropdown,
  Modal,
  Space,
  Tabs,
  Typography,
} from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../AppContext';
import {
  FACEBOOK_KEYS,
  MEDIA_NAME,
  NETWORK,
  PLATFORM_TYPE,
  REAUTHENTICATION,
  ROUTES,
} from '../../common/constants';
import history from '../../historyData';
import './accounts.less';
import Facebook from './components/Facebook';
import Instagram from './components/Instagram';
import Linkedin from './components/Linkedin';
import {
  ADD_SOCIAL_ACCOUNT,
  GET_ACCOUNT_REDIRECT_URL,
  GET_SOCIAL_ACCOUNT,
  REAUTHENTICATE_ACCOUNT,
  REMOVE_SOCIAL_ACCOUNT,
} from './graphql/Mutation';
import { GET_ACCOUNTS } from './graphql/Queries';

const { Title } = Typography;

function Accounts() {
  const {
    state: { code = null },
    updateNetwork,
    updateAccountCode,
  } = useContext(AppContext);
  const { pathname } = history?.location;
  const social = pathname?.split('/')?.[2];

  // eslint-disable-next-line no-undef, no-unused-vars
  const reAuth = JSON?.parse(localStorage?.getItem(REAUTHENTICATION));
  // eslint-disable-next-line no-undef
  const network = localStorage?.getItem(NETWORK);
  const [activeKey, setActiveKey] = useState(social || MEDIA_NAME?.LINKEDIN);
  const [modalOpen, setModalOpen] = useState(false);

  const [listAccountQuery, { data: accountData, loading }] = useLazyQuery(
    GET_ACCOUNTS,
    {
      fetchPolicy: 'network-only',
    },
  );

  useEffect(() => {
    listAccountQuery({
      variables: {
        filter: {
          platform: social?.toUpperCase(),
        },
      },
    });
  }, [social]);

  const [redirectUrl] = useMutation(GET_ACCOUNT_REDIRECT_URL, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      // eslint-disable-next-line no-undef
      window?.open(res?.getAccountRedirectUrl?.data?.redirectUrl, '_self');
    },
    onError() {},
  });

  const [getSocialAccount, { data }] = useMutation(GET_SOCIAL_ACCOUNT, {
    fetchPolicy: 'network-only',
    onError: () => {},
    onCompleted() {
      // eslint-disable-next-line no-undef
      localStorage?.removeItem(NETWORK);
      updateAccountCode(null);
    },
  });

  const account = data?.getSocialAccount?.data;

  const [addAccount, { loading: addAccountLoading }] = useMutation(
    ADD_SOCIAL_ACCOUNT,
    {
      fetchPolicy: 'network-only',
      onError() {},
      onCompleted: () => {
        updateAccountCode(null);
        listAccountQuery({
          variables: {
            filter: {
              platform: activeKey?.toUpperCase(),
            },
          },
        });
      },
    },
  );
  const [deleteSocialAccount] = useMutation(REMOVE_SOCIAL_ACCOUNT, {
    fetchPolicy: 'network-only',
    onCompleted() {
      updateAccountCode(null);
      listAccountQuery({
        variables: {
          filter: {
            platform: activeKey?.toUpperCase(),
          },
        },
      });
    },
    onError() {},
  });
  const [reAuthenticateAccount] = useMutation(REAUTHENTICATE_ACCOUNT, {
    fetchPolicy: 'network-only',
    onCompleted() {
      updateAccountCode(null);
      // eslint-disable-next-line no-undef
      localStorage?.removeItem(REAUTHENTICATION);
    },
    onError() {
      // eslint-disable-next-line no-undef
      localStorage?.removeItem(REAUTHENTICATION);
    },
  });

  useEffect(() => {
    if (activeKey?.toUpperCase() !== MEDIA_NAME?.FACEBOOK) {
      updateNetwork(activeKey?.toUpperCase());
    } else if (
      accountData?.listAccounts?.data?.[0]?.platformType === PLATFORM_TYPE?.PAGE
    ) {
      updateNetwork(FACEBOOK_KEYS?.FACEBOOKPAGE);
    } else {
      updateNetwork(FACEBOOK_KEYS?.FACEBOOKGROUP);
    }
  }, [activeKey, accountData]);

  const handleTab = (key) => {
    setActiveKey(key);
    history?.push(`${ROUTES?.ACCOUNTS}/${key}`);
  };

  useEffect(() => {
    if (code && reAuth) {
      reAuthenticateAccount({
        variables: {
          data: {
            code,
            network,
          },
        },
      });
    } else if (code) {
      getSocialAccount({
        variables: {
          data: {
            code,
            network,
          },
        },
      });
    }
  }, [code]);

  useEffect(() => {
    if (account?.length > 1) {
      setModalOpen(true);
    } else if (data) {
      addAccount({
        variables: {
          data: {
            userId: account?.[0]?.userId || '',
            firstName: account?.[0]?.firstName || '',
            lastName: account?.[0]?.lastName || '',
            platform: account?.[0]?.platform || '',
            platformId: account?.[0]?.platformId || '',
            platformType: account?.[0]?.platformType || '',
            avatar: account?.[0]?.avatar || '',
            profileUrl: account?.[0]?.profileUrl || '',
            descriptor: account?.[0]?.descriptor || '',
            accessToken: account?.[0]?.accessToken || '',
            isDisconnected: account?.[0]?.isDisconnected,
          },
        },
      });
    }
  }, [data]);

  const selectedAccount = (platformId) => {
    const accountToAdd = account?.find(
      (profile) => profile?.platformId === platformId,
    );
    if (accountToAdd) {
      addAccount({
        variables: {
          data: {
            userId: accountToAdd?.userId || '',
            firstName: accountToAdd?.firstName || '',
            lastName: accountToAdd?.lastName || '',
            platform: accountToAdd?.platform || '',
            platformId: accountToAdd?.platformId || '',
            platformType: accountToAdd?.platformType || '',
            avatar: accountToAdd?.avatar || '',
            profileUrl: accountToAdd?.profileUrl || '',
            descriptor: accountToAdd?.descriptor || '',
            accessToken: accountToAdd?.accessToken || '',
            isDisconnected: accountToAdd?.isDisconnected,
          },
        },
      });
    }
    setModalOpen(false);
  };

  const items = [
    {
      key: MEDIA_NAME?.LINKEDIN?.toLowerCase(),
      label: `LinkedIn`,
      children: (
        <Linkedin
          mutation={redirectUrl}
          deleteMutation={deleteSocialAccount}
          data={accountData?.listAccounts?.data}
          loading={loading || addAccountLoading}
        />
      ),
    },
    {
      key: MEDIA_NAME?.FACEBOOK?.toLowerCase(),
      label: `Facebook`,
      children: (
        <Facebook
          mutation={redirectUrl}
          deleteMutation={deleteSocialAccount}
          data={accountData?.listAccounts?.data}
          loading={loading || addAccountLoading}
        />
      ),
    },
    {
      key: MEDIA_NAME?.INSTAGRAM?.toLowerCase(),
      label: `Instagram`,
      children: (
        <Instagram
          mutation={redirectUrl}
          deleteMutation={deleteSocialAccount}
          data={accountData?.listAccounts?.data}
          loading={loading || addAccountLoading}
        />
      ),
    },
  ];

  const handleProfileId = (platformId) => {
    selectedAccount(platformId);
  };

  const dropDownItems = [
    {
      label: 'Add Page',
      key: FACEBOOK_KEYS?.FACEBOOKPAGE,
    },
    // Commented for future use.
    // {
    //   label: 'Add Group',
    //   key: FACEBOOK_KEYS?.FACEBOOKGROUP,
    // },
  ];

  const addAccountButton = () => {
    redirectUrl({
      variables: {
        data: { network: MEDIA_NAME?.[activeKey?.toUpperCase()] },
      },
    });
  };

  const addFacebookPageOrGroup = (value) => {
    redirectUrl({
      variables: {
        data: { network: FACEBOOK_KEYS?.[value] },
      },
    });
  };

  const handleChange = (menuItem) => {
    updateNetwork(FACEBOOK_KEYS?.[menuItem?.key]);
    addFacebookPageOrGroup(menuItem?.key);
  };

  return (
    <>
      <div className="add-accounts">
        <Title className="site-page-header p-0 mb-8 mt-0" level={3}>
          Accounts
        </Title>
        <Tabs
          type="card"
          activeKey={activeKey}
          items={items}
          onTabClick={handleTab}
          tabBarExtraContent={
            activeKey?.toUpperCase() === MEDIA_NAME?.FACEBOOK ? (
              <Dropdown
                menu={{ items: dropDownItems, onClick: handleChange }}
                trigger={['click']}
              >
                <Button type="primary">
                  <Space>
                    Add Account
                    <DownOutlined />
                  </Space>
                </Button>
              </Dropdown>
            ) : (
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={addAccountButton}
              >
                Add account
              </Button>
            )
          }
        />
        <Modal
          centered
          title="Please select Facebook Page to add:"
          closable
          confirmLoading={addAccountLoading}
          open={modalOpen}
          onOk={selectedAccount}
          footer={null}
          onCancel={() => setModalOpen(false)}
          wrapClassName="account-select"
        >
          <Space>
            {account?.map((profile) => (
              <>
                <Col>
                  <Card
                    hoverable
                    onClick={() => handleProfileId(profile?.platformId)}
                  >
                    <Card.Meta
                      avatar={<Avatar size="large" src={profile?.avatar} />}
                      title={profile?.descriptor}
                    />
                  </Card>
                </Col>
              </>
            ))}
          </Space>
        </Modal>
      </div>
    </>
  );
}

export default Accounts;
