import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Card, Divider, Form, Input, Space, Spin } from 'antd';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { GoogleLoginButton } from 'react-social-login-buttons';
import { AppContext } from '../../AppContext';
import { ROUTES } from '../../common/constants';
import { formValidatorRules } from '../../common/utils';
import { signIn } from '../../firebase';
import history from '../../historyData';
import './auth.less';
import { GOOGLE_LOGIN, LOGIN } from './graphql/Mutations';

const { required, email } = formValidatorRules;

const Login = () => {
  const [form] = Form.useForm();
  const { initializeAuth } = useContext(AppContext);
  const [loginMutate, { loading: loginLoading }] = useMutation(LOGIN, {
    onError() {},
  });

  const [GoogleLoginMutate, { data: googleData }] = useMutation(GOOGLE_LOGIN, {
    onError() {},
  });

  function successCallback(accessToken, userData, refreshToken, emailVerified) {
    initializeAuth(accessToken, userData, refreshToken, emailVerified);
    if (!emailVerified) {
      history?.replace(ROUTES?.EMAIL_VERIFICATION);
    } else {
      history?.replace(ROUTES?.MAIN);
    }
  }

  const onFinish = async (values) => {
    try {
      const formValues = {
        email: values?.email?.trim().toLowerCase(),
        password: values?.password?.trim(),
      };
      const response = await loginMutate({
        variables: { data: { ...formValues } },
      });
      if (response?.data) {
        const accessToken = response?.data?.signIn?.data?.accessToken;
        const userData = response?.data?.signIn?.data?.user;
        const tokenRefresh = response?.data?.signIn?.data?.refreshToken;
        const emailVerified = response?.data?.signIn?.data?.user?.emailVerified;

        if (successCallback) {
          successCallback(accessToken, userData, tokenRefresh, emailVerified);
        }
      } else {
        form?.setFieldsValue(values);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console?.error('error from login => ', error);
    }
  };

  const googleLogin = async (res) => {
    try {
      await GoogleLoginMutate({
        variables: {
          data: {
            token: `Bearer ${res?.[0]}`,
          },
        },
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console?.error('error from login => ', error);
    }
  };

  if (googleData) {
    const accessToken = googleData?.googleSignIn?.data?.accessToken;
    const userData = googleData?.googleSignIn?.data?.user;
    const tokenRefresh = googleData?.googleSignIn?.data?.refreshToken;
    const emailVerified = googleData?.googleSignIn?.data?.user?.emailVerified;
    if (successCallback) {
      successCallback(accessToken, userData, tokenRefresh, emailVerified);
    }
  }

  return (
    <div className="auth-bg">
      <div className="login-wrap d-flex align-center justify-start">
        <Card className="full-width">
          <Spin spinning={loginLoading} wrapperClassName="full-width">
            <Space className="logo">
              <img alt="logo" src="/logo.png" />
              Share Hive
            </Space>
            <GoogleLoginButton
              onClick={async () => {
                const res = await signIn();
                googleLogin(res);
              }}
            >
              Continue with Google
            </GoogleLoginButton>
            <Divider>OR</Divider>
            <Form
              name="Login"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              size="large"
              form={form}
            >
              <Form.Item
                name="email"
                rules={[{ required, message: 'Please enter email!' }, email]}
              >
                <Input prefix={<UserOutlined />} placeholder="Enter email" />
              </Form.Item>

              <Form.Item
                name="password"
                className="mb-8"
                rules={[{ required, message: 'Please enter password!' }]}
              >
                <Input.Password
                  prefix={<LockOutlined />}
                  placeholder="Enter password"
                />
              </Form.Item>
              <Form.Item className="text-right mb-8">
                <Link to={ROUTES?.FORGET_PASSWORD}>Forgot password ?</Link>
              </Form.Item>
              <Form.Item className=" full-width mb-8">
                <Button type="primary" className="full-width" htmlType="submit">
                  Login
                </Button>
              </Form.Item>
              <Form.Item className="text-center mb-0">
                <Space>
                  Don't have an account yet ?
                  <Button
                    type="link"
                    className="p-0"
                    onClick={() => {
                      history?.push(ROUTES?.SIGNUP);
                    }}
                  >
                    Signup
                  </Button>
                </Space>
              </Form.Item>
            </Form>
          </Spin>
        </Card>
        <div className="text-center login-copyrights">
          Share Hive © {new Date().getFullYear()}
        </div>
      </div>
    </div>
  );
};

export default Login;
