import {
  ArrowLeftOutlined,
  ExclamationCircleOutlined,
  ExportOutlined,
  FieldTimeOutlined,
  FileImageOutlined,
  FontSizeOutlined,
  FormOutlined,
  LinkOutlined,
  ScheduleOutlined,
  VideoCameraOutlined,
} from '@ant-design/icons';
import { useLazyQuery, useQuery } from '@apollo/client';
import { Avatar, Button, Card, Divider, Image, Space, Typography } from 'antd';
import { capitalize, includes } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import CarouselIcon from '../../assets/images/carouselIcon.png';
import imagePlaceHolder from '../../assets/images/placeholder.jpg';
import {
  MEDIA_NAME,
  MEDIA_STATUS,
  POST_TYPE,
  ROUTES,
  SHAREHIVE,
} from '../../common/constants';
import { getPlatformIconImage } from '../../common/utils';
import LoaderComponent from '../../components/LoaderComponent';
import history from '../../historyData';
import { GET_ACCOUNTS } from '../accounts/graphql/Queries';
import FacebookLayout from './components/FacebookLayout';
import InstagramLayout from './components/InstagramLayout';
import LinkedInLayout from './components/LinkedInLayout';
import { POST_DETAILS } from './graphql/Queries';
import './viewPost.less';

const { Title } = Typography;

function ViewPost() {
  const { postId } = useParams();
  const [postLoading, setPostLoading] = useState(true);
  const [postPreview, setPostPreview] = useState();
  const [userName, setUserName] = useState({
    FACEBOOK: SHAREHIVE,
    INSTAGRAM: SHAREHIVE,
    LINKEDIN: SHAREHIVE,
  });
  const [avatar, setAvatar] = useState({
    FACEBOOK: '',
    INSTAGRAM: '',
    LINKEDIN: '',
  });

  const [postDetails, { data: postData }] = useLazyQuery(POST_DETAILS, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      setPostLoading(false);
      setPostPreview({
        id: res?.postDetails?.post?.accountsData?.[0]?.id,
        platform:
          MEDIA_NAME?.[res?.postDetails?.post?.accountsData?.[0]?.platform],
      });
    },
  });

  const { data: accountData, loading: accountLoading } = useQuery(
    GET_ACCOUNTS,
    {
      fetchPolicy: 'network-only',
      onError() {},
    },
  );

  const postInfo = postData?.postDetails?.post;

  useEffect(() => {
    if (postId) {
      postDetails({
        variables: {
          where: { id: postId },
        },
      });
    }
  }, []);

  useEffect(() => {
    accountData?.listAccounts?.data?.find((profile) => {
      if (profile?.id === postPreview?.id) {
        setUserName({ ...userName, [profile?.platform]: profile?.descriptor });
        setAvatar({
          ...avatar,
          [profile?.platform]: profile?.avatar || profile?.profileUrl,
        });
      }
      return false;
    });
  }, [accountData, postPreview]);

  const handleBack = () => {
    history?.goBack();
  };

  const iconForMediaStatus = (status) => {
    if (status === MEDIA_STATUS?.SCHEDULED) {
      return (
        <div className="media-status-icon">
          <FieldTimeOutlined />
        </div>
      );
    }
    if (status === MEDIA_STATUS?.DRAFT) {
      return (
        <div className="media-status-icon">
          <FormOutlined />
        </div>
      );
    }
    if (status === MEDIA_STATUS?.FAILED) {
      return (
        <div className="media-status-icon">
          <ExclamationCircleOutlined />
        </div>
      );
    }
    return (
      <div className="media-status-icon">
        <ScheduleOutlined />
      </div>
    );
  };

  const iconForPostType = (postType) => {
    if (postType === POST_TYPE?.IMAGE) {
      return <FileImageOutlined />;
    }
    if (postType === POST_TYPE?.VIDEO) {
      return <VideoCameraOutlined />;
    }
    if (postType === POST_TYPE?.TEXT) {
      return <FontSizeOutlined />;
    }
    if (postType === POST_TYPE?.CAROUSEL) {
      return (
        <div className="event-image-icon">
          <Image preview={false} src={CarouselIcon} />
        </div>
      );
    }
    return <LinkOutlined />;
  };

  const videoSource = useMemo(() => {
    const isCarouselVideo =
      postInfo?.postType === POST_TYPE?.CAROUSEL &&
      postInfo?.assets?.filter((item) => item.type === 'VIDEO')?.length > 0;

    if (postInfo?.postType === POST_TYPE?.VIDEO || isCarouselVideo) {
      return postInfo?.assets
        ?.filter((item) => item.type === 'VIDEO')
        ?.map((asset) => ({
          name: asset?.location?.key,
          url: asset?.location?.url,
        }));
    }
  }, [postInfo]);

  const headerImageUrl = useMemo(() => {
    const isCarouselImage =
      postInfo?.postType === POST_TYPE?.CAROUSEL &&
      postInfo?.assets?.filter((item) => item.type === 'IMAGE')?.length > 0;

    if (postInfo?.postType === POST_TYPE?.IMAGE || isCarouselImage) {
      return postInfo?.assets
        ?.filter((item) => item.type === 'IMAGE')
        ?.map((asset) => ({
          name: asset?.location?.key,
          url: asset?.location?.url,
        }));
    }
  }, [postInfo]);

  const avatarViewer = (avatarSrc, avatarAlt) => (
    <Avatar className="post-avatar" alt={avatarAlt} src={avatarSrc} />
  );

  const mediaPreviewSelection = postInfo?.accountsData?.map((item) => {
    const data = accountData?.listAccounts?.data?.find(
      (profile) => item?.id === profile?.id,
    );
    return (
      <div
        key={data?.id}
        className={`selection-icon mr-0 ${
          data?.id === postPreview?.id &&
          postPreview?.platform === MEDIA_NAME?.[data?.platform] &&
          'active-selection'
        }`}
      >
        <Avatar
          onClick={() =>
            setPostPreview({
              id: data?.id,
              platform: MEDIA_NAME?.[data?.platform],
            })
          }
          alt={data?.descriptor}
          src={data?.avatar || data?.profileUrl || imagePlaceHolder}
        />
        <Image preview={false} src={getPlatformIconImage(data?.platform)} />
      </div>
    );
  });

  const postLink = postInfo?.postIdsWithAccountId?.find(
    (account) => account?.accountId === postPreview?.id,
  );

  if (postLoading || accountLoading) return <LoaderComponent />;

  return (
    <>
      <Title className="site-page-header p-0 mb-8 mt-0" level={3}>
        <Button
          type="text"
          shape="square"
          onClick={handleBack}
          icon={<ArrowLeftOutlined />}
        />
        <Divider type="vertical" />
        Post View
      </Title>
      <div className="post-view">
        <Card
          className="ant-body-scroll"
          title={
            <Space className="card-header">
              <div>
                <>
                  {iconForMediaStatus(postInfo?.status)}
                  {capitalize(postInfo?.status)}
                </>
                <Divider type="vertical" />
                <>
                  {iconForPostType(postInfo?.postType)}
                  {capitalize(postInfo?.postType)}
                </>
              </div>
              <Button
                disabled={postInfo?.status === MEDIA_STATUS?.PUBLISHED}
                type="primary"
                onClick={() => history?.push(`${ROUTES?.POST}/${postId}/edit`)}
              >
                <FormOutlined />
                Edit
              </Button>
            </Space>
          }
        >
          <div className="card-body-wrapper">
            <>
              <div className="post-priview-container">
                <div className="post-display">
                  <div className="media-selection-icons pointer">
                    {mediaPreviewSelection}
                  </div>
                  {postPreview?.platform === MEDIA_NAME?.INSTAGRAM &&
                    !includes(
                      [POST_TYPE?.TEXT, POST_TYPE?.LINK],
                      postInfo?.postType,
                    ) && (
                      <>
                        <InstagramLayout
                          instagramUserName={userName?.INSTAGRAM}
                          instagramAvatar={avatar?.INSTAGRAM}
                          description={postInfo?.description}
                          videoSource={videoSource}
                          postData={postData}
                          headerImageUrl={headerImageUrl}
                          avatarViewer={avatarViewer}
                        />
                      </>
                    )}
                  {postPreview?.platform === MEDIA_NAME?.FACEBOOK && (
                    <>
                      <FacebookLayout
                        facebookUserName={userName?.FACEBOOK}
                        facebookAvatar={avatar?.FACEBOOK}
                        description={postInfo?.description}
                        videoSource={videoSource}
                        postData={postData}
                        headerImageUrl={headerImageUrl}
                        avatarViewer={avatarViewer}
                      />
                    </>
                  )}
                  {postPreview?.platform === MEDIA_NAME?.LINKEDIN && (
                    <>
                      <LinkedInLayout
                        linkedinUserName={userName?.LINKEDIN}
                        linkedinAvatar={avatar?.LINKEDIN}
                        description={postInfo?.description}
                        videoSource={videoSource}
                        postData={postData}
                        headerImageUrl={headerImageUrl}
                        avatarViewer={avatarViewer}
                      />
                    </>
                  )}
                  {postInfo?.status === MEDIA_STATUS?.PUBLISHED && (
                    <Card className="platform-link pointer">
                      <div
                        onClick={() =>
                          // eslint-disable-next-line no-undef
                          window.open(postLink?.postLink, '_blank')
                        }
                      >
                        View Post on {capitalize(postPreview?.platform)}{' '}
                        <ExportOutlined />
                      </div>
                    </Card>
                  )}
                </div>
              </div>
            </>
          </div>
        </Card>
      </div>
    </>
  );
}
export default ViewPost;
