import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import Library from './Library';
import './library.less';

const LibraryWrapper = () => (
  <Switch>
    <Route exact path={ROUTES?.LIBRARY} component={Library} />
  </Switch>
);

export default LibraryWrapper;
