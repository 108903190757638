import { gql } from '@apollo/client';

export const LINKEDIN_POST = gql`
  mutation linkedInRegisterUpload($data: LinkedInRegisterUploadInput!) {
    linkedInRegisterUpload(data: $data) {
      data {
        uploadUrl
        asset
      }
    }
  }
`;
export const PUBLISH_POST = gql`
  mutation publishPost($data: PublishPostInput!, $where: DraftPostInput) {
    publishPost(data: $data, where: $where) {
      message
    }
  }
`;

export const SCHEDULE_POST = gql`
  mutation schedulePost($data: SchedulePostData!) {
    schedulePost(data: $data) {
      message
    }
  }
`;

export const UPDATE_POST = gql`
  mutation updatePost(
    $where: UpdatePostWhereUniqueInput!
    $data: UpdatePostData!
  ) {
    updatePost(where: $where, data: $data) {
      message
    }
  }
`;

export const UPDATE_POST_V2 = gql`
  mutation UpdatePostV2(
    $where: UpdatePostV2WhereUniqueInput!
    $data: UpdatePostData!
  ) {
    updatePostV2(where: $where, data: $data) {
      message
    }
  }
`;
