import React, { useEffect } from 'react';
import { Redirect, Route, Switch, useParams } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import history from '../../historyData';
import './accounts.less';

const AccountsWrapper = () => {
  const { social } = useParams();
  useEffect(() => {
    if (!social) {
      history?.push(`${ROUTES?.ACCOUNTS}/linkedin`);
    }
  }, []);

  return (
    <Switch>
      <Route
        exact
        path={ROUTES?.ACCOUNTS}
        render={() => <Redirect to={`${ROUTES?.ACCOUNTS}/:social`} />}
      />
    </Switch>
  );
};

export default AccountsWrapper;
