import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const LIST_POST = gql`
  query ListPosts($filter: PostFilter) {
    listPosts(filter: $filter) {
      count
      posts {
        id
        accountId
        userId
        description
        textFormatted
        isPost
        isReel
        isStory
        isMultiplePlatform
        link
        postId
        status
        scheduledAt
        createdAt
        updatedAt
        accounts {
          id
          userId
          platform
          platformId
          descriptor
          platformType
        }
        postType
        assets {
          id
          location {
            key
            url
          }
          thumbnail
          altText
          type
          title
          fileSize
          fileExtension
          duration
          width
          height
        }
      }
    }
  }
`;

export const LIST_POST_V2 = gql`
  query ListPostsV2($filter: PostFilter) {
    listPostsV2(filter: $filter) {
      count
      posts {
        commonPostId
        ids
        userId
        description
        isPost
        textFormatted
        isReel
        isStory
        link
        status
        createdAt
        scheduledAt
        updatedAt
        postType
        isMultiplePlatform
        postStatusWithAccountId {
          id
          platform
          descriptor
          status
        }
        accounts {
          id
          userId
          platformId
          platformType
          platform
          avatar
          lastName
          firstName
          descriptor
          accessToken
          profileUrl
        }
        assets {
          id
          location {
            key
            url
          }
          thumbnail
          altText
          type
          title
          fileSize
          fileExtension
          duration
          width
          height
        }
      }
    }
  }
`;
