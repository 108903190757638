import { gql } from '@apollo/client';

export const UPDATE_PROFILE = gql`
  mutation updateProfile($data: UpdateProfileInput!) {
    updateProfile(data: $data) {
      data {
        id
        firstName
        lastName
        avatar {
          key
          url
        }
        timeZone
        emailVerified
        authProvider
      }
      message
    }
  }
`;
export const DELETE_PROFILE = gql`
  mutation deleteProfile {
    deleteProfile {
      message
    }
  }
`;
export const CHANGE_PASSWORD = gql`
  mutation changePassword($data: ChangePasswordInput!) {
    changePassword(data: $data) {
      message
    }
  }
`;
