import { gql } from '@apollo/client';

export const REFRESH_TOKEN = gql`
  query newAccessToken($refreshToken: String!) {
    newAccessToken(refreshToken: $refreshToken) {
      data {
        accessToken
        user {
          id
          firstName
          lastName
          email
          avatar
          timeZone
          emailVerified
          firebaseId
          authProvider
        }
      }
    }
  }
`;

export const GET_CURRENT_USER = gql`
  query getCurrentUser {
    getCurrentUser {
      data {
        authProvider
        id
        firstName
        lastName
        email
        emailVerified
        avatar {
          url
          key
        }
        timeZone
      }
    }
  }
`;
