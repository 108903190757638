import {
  LikeOutlined,
  MessageOutlined,
  SendOutlined,
  ShareAltOutlined,
} from '@ant-design/icons';
import { Carousel, Image } from 'antd';
import React, { useState } from 'react';
import ImagePlaceholder from '../../../assets/images/imagePlaceholder.png';
import { videoViewer } from '../../../common/utils';
import '../create.less';

const LinkedInLayout = (props) => {
  const {
    linkedinUserName,
    description,
    videoSource,
    avatarViewer,
    headerImageUrl,
    linkedinAvatar,
  } = props;

  const ReadMoreContent = ({ text, maxChar }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleReadMore = () => {
      setIsExpanded(!isExpanded);
    };

    return (
      <div className="description">
        {isExpanded ? (
          <div>
            {text}
            <span
              onClick={(e) => {
                e?.stopPropagation();
                toggleReadMore();
              }}
              className="show-more text-primary mt-4 pointer inline-block"
            >
              Show Less
            </span>
          </div>
        ) : (
          <div>
            {text?.slice(0, maxChar)}
            {text?.length > maxChar && (
              <span
                onClick={(e) => {
                  e?.stopPropagation();
                  toggleReadMore();
                }}
                className="show-more text-primary mt-4 pointer inline-block"
              >
                ...Show More
              </span>
            )}
          </div>
        )}
      </div>
    );
  };
  return (
    <div className="post facebook">
      <div className="post-header">
        {avatarViewer(linkedinAvatar, linkedinUserName)}
        <div className="post-info">
          <h2>{linkedinUserName}</h2>
          <p>Job Description</p>
        </div>
      </div>
      <div className="post-body">
        <ReadMoreContent text={description || ' '} maxChar={170} />
      </div>
      <div className="post-image">
        <Carousel autoplay>
          {headerImageUrl?.length > 0 &&
            headerImageUrl.map((img) => (
              <div key={img?.name}>
                <Image preview={false} src={img?.url || ImagePlaceholder} />
              </div>
            ))}
          {videoSource?.length > 0 &&
            videoSource.map((video) => (
              <div key={video?.name} className="post-video">
                {videoViewer(video, videoSource)}
              </div>
            ))}
        </Carousel>
      </div>
      <div className="post-footer">
        <div className="footer-icons">
          <LikeOutlined />
          <h4>Like</h4>
        </div>
        <div className="footer-icons">
          <MessageOutlined />
          <h4>Comment</h4>
        </div>
        <div className="footer-icons">
          <SendOutlined />
          <h4>Repost</h4>
        </div>
        <div className="footer-icons">
          <ShareAltOutlined />
          <h4>Send</h4>
        </div>
      </div>
    </div>
  );
};

export default LinkedInLayout;
