import React, { useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { AppContext } from './AppContext';
import { ROUTES } from './common/constants';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { getToken } = useContext(AppContext);
  const idToken = getToken();

  const renderComponent = (props) => {
    if (!idToken) {
      return <Redirect to={ROUTES?.LOGIN} />;
    }
    return <Component {...props} />;
  };

  return <Route {...rest} render={(props) => renderComponent(props)} />;
};
export default PrivateRoute;
