import { useLazyQuery } from '@apollo/client';
import { omit } from 'lodash';
import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import { ROUTES } from '../../common/constants';
import LoaderComponent from '../../components/LoaderComponent';
import { REFRESH_TOKEN } from './graphql/Queries';

const RefreshToken = () => {
  const { initializeAuth, getRefreshToken } = useContext(AppContext);
  const history = useHistory();
  const {
    location: { state },
  } = history;
  const refreshToken = getRefreshToken();
  function successCallback(accessToken, userData, emailVerified) {
    initializeAuth(accessToken, userData, '', emailVerified);
  }

  const [refresh, { loading }] = useLazyQuery(REFRESH_TOKEN, {
    fetchPolicy: 'network-only',
    onCompleted(response) {
      const accessToken = response?.newAccessToken?.data?.accessToken;
      const userData = omit(response?.newAccessToken?.data?.user, '__typename');
      const emailVerified = response?.newAccessToken?.data?.user?.emailVerified.toString();
      successCallback(accessToken, userData, emailVerified);
      if (state?.from !== ROUTES?.AUTHENTICATION) {
        history?.replace(state?.from);
      } else {
        history?.replace(ROUTES?.MAIN);
      }
    },
    onError(err) {
      // eslint-disable-next-line no-console
      console.log('in error', err);
      history?.replace(ROUTES?.LOGOUT);
    },
  });

  useEffect(() => {
    refresh({
      variables: {
        refreshToken: `Bearer ${refreshToken}`,
      },
    });
  }, []);

  if (loading) return <LoaderComponent />;
  return null;
};

export default RefreshToken;
