import { gql } from '@apollo/client';

export const LOGIN = gql`
  mutation signIn($data: SignInInput!) {
    signIn(data: $data) {
      data {
        refreshToken
        accessToken
        user {
          id
          firstName
          lastName
          email
          avatar {
            key
            url
          }
          timeZone
          emailVerified
          refreshToken
          firebaseId
          authProvider
        }
      }
    }
  }
`;
export const GOOGLE_LOGIN = gql`
  mutation googleSignIn($data: GoogleSignInInput!) {
    googleSignIn(data: $data) {
      data {
        refreshToken
        accessToken
        user {
          id
          firstName
          lastName
          email
          avatar {
            key
            url
          }
          timeZone
          emailVerified
          refreshToken
          firebaseId
          authProvider
        }
      }
    }
  }
`;

export const SIGNUP = gql`
  mutation signUp($data: SignUpInput!) {
    signUp(data: $data) {
      data {
        id
        firstName
        lastName
        email
        avatar {
          key
          url
        }
        timeZone
        emailVerified
        refreshToken
        accessToken
        firebaseId
        authProvider
      }
      message
    }
  }
`;

export const SIGN_OUT = gql`
  mutation signOut {
    signOut {
      message
    }
  }
`;
export const FORGOT_PASSWORD = gql`
  mutation resetPwdRequest($data: ResetPwdRequestInput!) {
    resetPwdRequest(data: $data) {
      message
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation resetPassword($data: ResetPasswordInput!) {
    resetPassword(data: $data) {
      message
    }
  }
`;
export const EMAIL_VERIFICATION = gql`
  mutation verifyEmail($data: VerifyEmailInput!) {
    verifyEmail(data: $data) {
      message
    }
  }
`;

export const RESEND_VERIFICATION_CODE = gql`
  mutation resendVerificationCode {
    resendVerificationCode {
      message
    }
  }
`;
