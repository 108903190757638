import { gql } from '@apollo/client';

export const GET_ACCOUNT_REDIRECT_URL = gql`
  mutation getAccountRedirectUrl($data: GetAccountRedirectUrlInput!) {
    getAccountRedirectUrl(data: $data) {
      data {
        redirectUrl
      }
    }
  }
`;
export const GET_SOCIAL_ACCOUNT = gql`
  mutation getSocialAccount($data: GetSocialAccountInput!) {
    getSocialAccount(data: $data) {
      data {
        userId
        firstName
        lastName
        platform
        platformId
        platformType
        avatar
        profileUrl
        descriptor
        accessToken
        isDisconnected
        isAlreadyAdded
      }
    }
  }
`;
export const ADD_SOCIAL_ACCOUNT = gql`
  mutation addSocialAccount($data: AddSocialAccountInput!) {
    addSocialAccount(data: $data) {
      message
    }
  }
`;
export const REMOVE_SOCIAL_ACCOUNT = gql`
  mutation removeAccount($data: RemoveAccountInput!) {
    removeAccount(data: $data) {
      message
    }
  }
`;
export const REAUTHENTICATE_ACCOUNT = gql`
  mutation reAuthenticateAccount($data: ReAuthenticateAccountInput!) {
    reAuthenticateAccount(data: $data) {
      message
    }
  }
`;
