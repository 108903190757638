import {
  ArrowLeftOutlined,
  DeleteOutlined,
  InboxOutlined,
  LinkOutlined,
  PlusOutlined,
  SmileOutlined,
} from '@ant-design/icons';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import Picker from '@emoji-mart/react';
import {
  Alert,
  Avatar,
  Button,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Empty,
  Form,
  Image,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Switch,
  Tag,
  Typography,
  Upload,
} from 'antd';
import dayjs from 'dayjs';
import {
  capitalize,
  flatMap,
  includes,
  isEmpty,
  min,
  omit,
  round,
  uniq,
} from 'lodash';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import client from '../../apollo';
import imagePlaceHolder from '../../assets/images/placeholder.jpg';
import SocialPostIllustration from '../../assets/svg/addSocialPost.svg';
import {
  ALLOWED_IMAGES_TYPE,
  ALLOWED_TYPE_CAROUSEL,
  CHARACTER_LIMIT,
  MEDIA_NAME,
  MEDIA_STATUS,
  POST_TYPE,
  POST_TYPE_FOR_INSTAGRAM,
  POST_TYPE_OPTIONS,
  REGEX,
  ROUTES,
  SHAREHIVE,
  VIDEO_LENGTH,
  defaultDateFormat,
} from '../../common/constants';
import {
  checkFileSize,
  fileUpload,
  getBase64File,
  getPlatformIconImage,
  getSignedUrl,
} from '../../common/utils';
import LoaderComponent from '../../components/LoaderComponent';
import history from '../../historyData';
import { GET_ACCOUNTS } from '../accounts/graphql/Queries';
import FacebookLayout from './components/FacebookLayout';
import InstagramLayout from './components/InstagramLayout';
import LinkedInLayout from './components/LinkedInLayout';
import './create.less';
import {
  LINKEDIN_POST,
  PUBLISH_POST,
  SCHEDULE_POST,
  UPDATE_POST_V2,
} from './graphql/Mutation';
import { POST_DETAILS } from './graphql/Queries';

const { Title } = Typography;
const { TextArea } = Input;
const { Option } = Select;

function Post(props) {
  const {
    location: { data: scheduleDate },
  } = props;
  const { postId } = useParams();
  const [postStatus, setPostStatus] = useState(MEDIA_STATUS?.PUBLISHED);
  const logo = '/logo.png';

  const [form] = Form?.useForm();
  const { dispatch } = useContext(AppContext);

  const [checked, setChecked] = useState(false);
  const [planned, setPlanned] = useState(scheduleDate?.schedule || false);
  const [scheduledAt, setScheduledAt] = useState(
    scheduleDate?.start || dayjs(),
  );
  const [userName, setUserName] = useState({
    FACEBOOK: SHAREHIVE,
    INSTAGRAM: SHAREHIVE,
    LINKEDIN: SHAREHIVE,
  });
  const [avatar, setAvatar] = useState({
    FACEBOOK: logo,
    INSTAGRAM: logo,
    LINKEDIN: logo,
  });
  const [fileList, setFileList] = useState([]);
  const [videoSource, setVideoSource] = useState([]);
  const [videoDuration, setVideoDuration] = useState();
  const [headerImageUrl, setHeaderImageUrl] = useState([]);
  const [socialName, setSocialName] = useState([]);
  const [postType, setPostType] = useState(null);
  const [postTypeOption, setPostTypeOption] = useState();
  const [link, setLink] = useState('');
  const [btnLoading, setBtnLoading] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [emojiPicker, setEmojiPicker] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [isFieldsChanged, setIsFieldsChanged] = useState(false);
  const [isCancel, setIsCancel] = useState(false);
  const [postPreview, setPostPreview] = useState();
  const [showPrompt, setShowPrompt] = useState(false);
  const description = Form.useWatch('description', form);
  const [errors, setErrors] = useState({});

  const checkPlatformExist = (platform) =>
    socialName?.some((data) => Object.values(data)?.includes(platform)) ??
    false;

  const isAccount = {
    instagram: checkPlatformExist(MEDIA_NAME.INSTAGRAM),
    facebook: checkPlatformExist(MEDIA_NAME.FACEBOOK),
    linkedin: checkPlatformExist(MEDIA_NAME.LINKEDIN),
  };

  const pushValue = (key, value) => {
    setErrors((prevState) => {
      // Check if the key exists in the object
      if (Object.hasOwnProperty.call(prevState, key)) {
        // If the key exists, push the new value into its array
        return {
          ...prevState,
          [key]: [...prevState[key], value],
        };
      }
      // If the key doesn't exist, create a new key with an array containing the new value
      return {
        ...prevState,
        [key]: [value],
      };
    });
  };

  const { data: accountData, loading: accountLoading } = useQuery(
    GET_ACCOUNTS,
    {
      fetchPolicy: 'network-only',
      onError() {},
    },
  );

  const [postDetails, { data: postData, loading: postLoading }] = useLazyQuery(
    POST_DETAILS,
    {
      fetchPolicy: 'network-only',
      onError() {},
    },
  );

  const postInfo = postData?.postDetails?.post;

  const [publishPost, { loading }] = useMutation(PUBLISH_POST, {
    fetchPolicy: 'network-only',
    onCompleted() {
      history?.push(ROUTES?.LIBRARY);
    },
    onError() {},
  });
  const [scheduledPost, { loading: scheduleLoading }] = useMutation(
    SCHEDULE_POST,
    {
      fetchPolicy: 'network-only',
      onCompleted() {
        setModalOpen(false);
        history?.push(ROUTES?.LIBRARY);
      },
      onError() {},
    },
  );
  const [updatePost, { loading: updateLoading }] = useMutation(UPDATE_POST_V2, {
    fetchPolicy: 'network-only',
    onCompleted() {
      history?.push(ROUTES?.LIBRARY);
    },
    onError() {},
  });

  useEffect(() => {
    accountData?.listAccounts?.data?.find((profile) => {
      if (profile?.id === postPreview?.id) {
        setUserName({ ...userName, [profile?.platform]: profile?.descriptor });
        setAvatar({
          ...avatar,
          [profile?.platform]: profile?.avatar || profile?.profileUrl,
        });
      }
      return false;
    });
  }, [accountData, postPreview]);

  useEffect(() => {
    if (postId) {
      postDetails({
        variables: {
          where: { id: postId },
        },
      });
    }
    if (!postId) {
      form?.resetFields();
      setFileList([]);
      setHeaderImageUrl([]);
      setSocialName([]);
      setPostType(null);
      setLink('');
      setChecked(false);
    }
  }, [postId]);

  useEffect(() => {
    if (postData) {
      setPlanned(!!postInfo?.scheduledAt);
      const assetFile = postInfo?.assets?.map((asset) => ({
        url: asset?.location?.url,
        name: asset?.location?.key,
      }));
      setFileList(assetFile);
      const headerImageUrlArray = [];
      const videoSourceArray = [];
      postInfo?.assets?.map((asset) =>
        asset?.type === POST_TYPE?.VIDEO
          ? videoSourceArray?.push({
              name: asset?.location?.key,
              url: asset?.location?.url,
            })
          : headerImageUrlArray?.push({
              url: asset?.location?.url,
              name: asset?.location?.key,
            }),
      );
      setVideoSource(videoSourceArray);
      setHeaderImageUrl(headerImageUrlArray);
      form?.setFieldValue('imageUrl', assetFile);
      form?.setFieldValue('description', postInfo?.description);
      setScheduledAt(postInfo?.scheduledAt);
      setPostPreview({
        id: postInfo?.accounts?.id,
        platform: postInfo?.accounts?.platform,
      });
      const defaultSocialName = [];
      const socialNameSelectValue = [];
      postInfo?.accountsData?.map((account) => {
        defaultSocialName?.push({ [account?.id]: account?.platform });
        socialNameSelectValue?.push({
          value: account?.id,
          label: (
            <>
              <div className="select-media-icons">
                <Image
                  preview={false}
                  src={getPlatformIconImage(account?.platform)}
                />
                {account?.descriptor}
              </div>
            </>
          ),
        });
        return null;
      });
      setSocialName(defaultSocialName);
      form.setFieldValue('socialmedia', socialNameSelectValue);
      setPostStatus(
        postInfo?.status === MEDIA_STATUS?.FAILED && postInfo?.scheduledAt
          ? MEDIA_STATUS?.SCHEDULED
          : postInfo?.status,
      );
      setPostType(postInfo?.postType);
      setChecked(true);
      form?.setFieldValue('postType', postInfo?.postType);
      if (includes(MEDIA_NAME?.INSTAGRAM, postInfo?.platform)) {
        setPostTypeOption(POST_TYPE_FOR_INSTAGRAM);
      } else {
        setPostTypeOption(POST_TYPE_OPTIONS);
      }
    }
  }, [postData]);

  const handleSocialOption = (value, ...rest) => {
    const accounts = [];
    let preview;
    rest?.[0]?.map((data) => {
      accounts?.push({ [data?.value]: data?.['data-platform'] });
      preview = { id: data?.value, platform: data?.['data-platform'] };
      return null;
    });
    setSocialName(accounts);
    setPostPreview(preview);
    if (postType) {
      form.validateFields(['postType']);
    }
    if (
      includes(
        MEDIA_NAME?.INSTAGRAM,
        accounts?.map((item) => item?.[value]),
      )
    ) {
      setPostTypeOption(POST_TYPE_FOR_INSTAGRAM);
    } else {
      setPostTypeOption(POST_TYPE_OPTIONS);
    }
    if (!value?.length) {
      setChecked(false);
      setPostType(null);
      form?.setFieldValue('postType', null);
    } else {
      setChecked(true);
    }
  };

  const handlePostOption = (value) => {
    setPostType(value);
    setFileList([]);
    setHeaderImageUrl([]);
    setVideoSource([]);
  };

  const accountToPost = socialName?.map((item) =>
    accountData?.listAccounts?.data?.find(
      (profile) => item?.[profile?.id] && profile,
    ),
  );

  const handlePreviewSwitch = (value) => {
    setChecked(value);
  };
  const handleDescription = (e) => {
    if (REGEX?.WEB_URL?.test(e?.target?.value)) {
      setLink(e?.target?.value);
    } else {
      form?.setFieldValue('description', e?.target?.value);
    }
  };

  /** ****************Tags***************** */
  const [tags, setTags] = useState([]);
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState('#');
  const [editInputIndex, setEditInputIndex] = useState(-1);
  const [editInputValue, setEditInputValue] = useState('');
  const inputRef = useRef(null);
  const editInputRef = useRef(null);

  useEffect(() => {
    if (inputVisible) {
      inputRef?.current?.focus();
    }
  }, [inputVisible]);
  useEffect(() => {
    editInputRef?.current?.focus();
  }, [inputValue]);
  const handleClose = (removedTag) => {
    const newTags = tags?.filter((tag) => tag !== removedTag);
    setTags(newTags);
  };
  const showInput = () => {
    setInputVisible(true);
  };
  const handleInputChange = (e) => {
    setInputValue(e?.target?.value);
  };
  const handleInputConfirm = () => {
    if (inputValue?.length > 1 && tags?.indexOf(inputValue) === -1) {
      setTags([...tags, inputValue]);
    }
    setInputVisible(false);
    setInputValue('#');
  };
  const handleEditInputChange = (e) => {
    setEditInputValue(e?.target?.value);
  };
  const handleEditInputConfirm = () => {
    const newTags = [...tags];
    newTags[editInputIndex] = editInputValue;
    setTags(newTags);
    setEditInputIndex(-1);
    setInputValue('#');
  };
  const tagInputStyle = {
    width: 78,
    verticalAlign: 'top',
  };
  const tagPlusStyle = {
    borderStyle: 'dashed',
  };
  /** ****************Tags End***************** */

  const checkAssetsValidations = (file) => {
    if (
      includes(
        [MEDIA_STATUS?.PUBLISHED, MEDIA_STATUS?.SCHEDULED],
        postStatus,
      ) &&
      !file
    ) {
      setUploadLoading(false);
      pushValue(postType, `Please add ${postType?.toLowerCase()}!`);
    }
    if (file?.status === 'removed') {
      setUploadLoading(false);
      return;
    }
    // eslint-disable-next-line no-undef
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.addEventListener('load', (event) => {
        const loadedImageUrl = event.target.result;
        const isImage = file.type.startsWith('image/');
        if (isImage) {
          // eslint-disable-next-line no-undef
          const img = document.createElement('img');
          img.addEventListener('load', () => {
            const { width, height } = img;
            if (
              round(width / height, 2) > 1.78 ||
              round(height / width, 2) > 1.78
            ) {
              pushValue(
                file.name,
                'Image ratio should be less than 16:9 or 9:16. Please check and try again!',
              );
              reject();
            }
            if (
              includes(ALLOWED_IMAGES_TYPE, file?.type) &&
              checkFileSize(file?.size) > 8
            ) {
              pushValue(file.name, `Image size should be less then 8mb!`);
              reject();
            }
            img.parentNode.removeChild(img);
            if (errors.length > 0) {
              setUploadLoading(false);
              reject(errors);
            } else {
              setUploadLoading(false);
              resolve();
            }
          });
          // eslint-disable-next-line no-undef
          document.body.appendChild(img);
          img.src = loadedImageUrl;
        } else if (!isImage) {
          // eslint-disable-next-line no-undef
          const video = document.createElement('video');
          const platform = [];
          socialName?.map((media) => {
            if (videoDuration > VIDEO_LENGTH?.[Object.values(media)]) {
              return platform?.push(Object.values(media));
            }
            return null;
          });
          video.addEventListener('loadedmetadata', () => {
            const { videoWidth, videoHeight } = video;
            if (
              round(videoWidth / videoHeight, 2) > 1.78 ||
              round(videoHeight / videoWidth, 2) > 1.78
            ) {
              pushValue(
                file.name,
                'Video ratio should be less than 16:9 or 9:16. Please check and try again!',
              );
            }
            if (
              postType === POST_TYPE?.CAROUSEL &&
              [MEDIA_NAME?.FACEBOOK, MEDIA_NAME?.LINKEDIN].some((item) =>
                socialName?.some((obj) => Object.values(obj)?.includes(item)),
              ) &&
              file?.type === 'video/mp4'
            ) {
              pushValue(
                file.name,
                `Currently, videos cannot be added to Facebook and LinkedIn carousel.`,
              );
            }
            if (
              [MEDIA_NAME?.INSTAGRAM].some((item) =>
                socialName?.some((obj) => Object.values(obj)?.includes(item)),
              ) &&
              !isImage &&
              videoHeight > 1920
            ) {
              pushValue(
                file.name,
                'Video Dimension should be under 1080 x 1920p for Instagram. Please check and try again!',
              );
            }
            if (!isEmpty(platform)) {
              pushValue(
                file.name,
                `The duration of video is not supported for ${capitalize(
                  platform,
                )}`,
              );
            }
            if (
              includes(['video/mp4'], file?.type) &&
              checkFileSize(file?.size) > 100
            ) {
              pushValue(file.name, `Video size should be less then 100mb!`);
            }
            video.parentNode.removeChild(video);
            if (errors.length > 0) {
              setUploadLoading(false);
              reject(errors);
            } else {
              setUploadLoading(false);
              resolve();
            }
          });
          // eslint-disable-next-line no-undef
          document.body.appendChild(video);
          video.src = loadedImageUrl;
        } else {
          resolve(); // For other types, just resolve
        }
      });
      reader.readAsDataURL(file);
    });
  };

  /** **************Upload Image*************** */
  const handleChange = (value) => {
    setUploadLoading(true);
    setFileList(value?.fileList);
    if (!isEmpty(form?.getFieldValue('socialmedia'))) {
      checkAssetsValidations(value?.file);
    }
    if (value?.file?.status === 'removed') {
      setVideoDuration(0);
      if (Object?.keys(errors)?.length > 0) {
        setErrors(
          Object.fromEntries(
            Object.entries(errors).filter(([key]) => key !== value?.file?.name),
          ),
        );
      }
      setHeaderImageUrl(
        headerImageUrl?.filter((item) => item?.name !== value?.file?.name),
      );
      setVideoSource(
        videoSource?.filter((item) => item?.name !== value?.file?.name),
      );
      return;
    }
    if (value?.fileList?.length > 0)
      if (value?.file?.type === 'video/mp4') {
        const url = URL.createObjectURL(value?.file);
        setVideoSource([...videoSource, { name: value?.file?.name, url }]);
        // eslint-disable-next-line no-undef
        const videoElement = document?.createElement('video');
        // eslint-disable-next-line no-undef
        document?.body?.appendChild(videoElement);
        videoElement.src = url;
        videoElement?.addEventListener('loadedmetadata', () => {
          const durationInSeconds = videoElement?.duration;
          setVideoDuration(durationInSeconds);
        });
        // eslint-disable-next-line no-undef
        document?.body?.removeChild(videoElement);
      } else {
        getBase64File(value?.file, (imageUrl) =>
          setHeaderImageUrl([
            ...headerImageUrl,
            { name: value?.file?.name, url: imageUrl },
          ]),
        );
      }
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  const handleUploadImage = async (imageObject) => {
    const isPublicBucketCall =
      postType === POST_TYPE.CAROUSEL && isAccount?.facebook;
    try {
      const responseOfSignedUrl = [];
      if (isPublicBucketCall) {
        const fileData = await getSignedUrl(imageObject, true)?.then((res) =>
          omit(res?.getPublicSignedPutUrl?.data, ['__typename']),
        );
        responseOfSignedUrl?.push({
          fileData,
          imageObject,
          isPublicAsset: true,
        });
      }
      if (
        includes(
          [
            POST_TYPE.IMAGE,
            POST_TYPE.VIDEO,
            (isAccount?.instagram || isAccount?.linkedin) &&
              POST_TYPE?.CAROUSEL,
          ],
          postType,
        )
      ) {
        const fileData = await getSignedUrl(imageObject)?.then((res) =>
          omit(res?.getSignedPutUrl?.data, ['__typename']),
        );
        responseOfSignedUrl?.push({
          fileData,
          imageObject,
          isPublicAsset: false,
        });
      }
      return responseOfSignedUrl;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error while uploading image', error);
    }
  };

  const handleLinkedInUploadImage = async (asset, linkedInAssets) => {
    try {
      await Promise.all(
        accountToPost?.map(async (account) => {
          if (account?.platform === MEDIA_NAME?.LINKEDIN) {
            try {
              const response = await client.mutate({
                mutation: LINKEDIN_POST,
                variables: {
                  data: {
                    mediaType:
                      postType === POST_TYPE?.CAROUSEL
                        ? POST_TYPE?.IMAGE
                        : postType,
                    isCarousel: postType === POST_TYPE?.CAROUSEL,
                    accessToken: account?.accessToken,
                    target: 'person',
                    platformId: account?.platformId,
                    postKey: asset?.key,
                  },
                },
              });
              linkedInAssets?.push({
                key: response?.data?.linkedInRegisterUpload?.data?.asset,
                platformId: account?.platformId,
              });
            } catch (error) {
              // eslint-disable-next-line no-console
              console.error('Error while uploading image', error);
            }
          }
        }),
      );
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error', error);
    }
  };

  const commonFileUpload = async ({
    assets,
    faceBookCarouselAssets,
    dataToUpload,
  }) => {
    await Promise?.all(
      dataToUpload?.flat(2)?.map(async (data) => {
        await fileUpload(data?.fileData?.signedUrl, data?.imageObject);
        if (data?.isPublicAsset) {
          faceBookCarouselAssets?.push({
            key: data?.fileData?.key,
            type: data?.imageObject?.type?.slice(0, 5)?.toUpperCase(),
          });
        } else {
          assets?.push({
            key: data?.fileData?.key,
            type: data?.imageObject?.type?.slice(0, 5)?.toUpperCase(),
          });
        }
      }),
    );
  };

  const submitMediaStatus = async (
    ids,
    values,
    assets,
    linkedInAssets,
    faceBookCarouselAssets,
  ) => {
    if (planned && !postId) {
      await scheduledPost({
        variables: {
          data: {
            accountIds: ids,
            link,
            linkedInAssets,
            faceBookCarouselAssets,
            message:
              tags?.length > 0
                ? `${values?.description} ${tags?.join(' ')}`
                : values?.description,
            postStatus,
            postType,
            scheduledAt,
            assets: assets?.length > 0 ? assets : [],
          },
        },
      });
    } else if (
      (postId &&
        includes([MEDIA_STATUS?.DRAFT, MEDIA_STATUS?.FAILED], postStatus)) ||
      (planned && includes([MEDIA_STATUS?.SCHEDULED], postStatus))
    ) {
      updatePost({
        variables: {
          where: {
            ids: postInfo?.postIds,
          },
          data: {
            link,
            message:
              tags?.length > 0
                ? `${values?.description} ${tags?.join(' ')}`
                : values?.description,
            postType,
            postStatus: planned ? MEDIA_STATUS?.SCHEDULED : postStatus,
            scheduledAt: planned ? scheduledAt : null,
            assets: assets?.length > 0 ? assets : [],
            linkedInAssets,
            faceBookCarouselAssets,
          },
        },
      });
    } else {
      const draftIdsWithAccountIds = postInfo?.postIdsWithAccountId?.map(
        (postids) => ({
          id: postids?.id,
          accountId: postids?.accountId,
        }),
      );
      await publishPost({
        variables: {
          where: {
            draftIdsWithAccountId: draftIdsWithAccountIds || [],
          },
          data: {
            postData: {
              postType,
              message:
                tags?.length > 0
                  ? `${values?.description} ${tags?.join(' ')}`
                  : values?.description,
              accountIds: ids,
              linkedInAssets,
              faceBookCarouselAssets,
              link,
              postStatus,
              assets: assets?.length > 0 ? assets : [],
            },
          },
        },
      });
    }
  };
  /** **************Upload Image End*************** */
  const onFinish = async (values) => {
    const assets = [];
    const ids = [];
    const linkedInAssets = [];
    const faceBookCarouselAssets = [];
    const dataToUpload = [];
    accountToPost?.map((item) => ids?.push(item?.id));
    setBtnLoading(true);
    dispatch({
      type: 'SET_SHOW_PROMPT',
      data: false,
    });
    try {
      const dataToSend = {
        ...values,
      };
      if (!isEmpty(values?.imageUrl)) {
        if (values?.imageUrl?.fileList?.length > 0) {
          await Promise?.all(
            values?.imageUrl?.fileList?.map(async (imageObject) => {
              if (imageObject?.originFileObj) {
                await Promise.all([
                  await handleUploadImage(imageObject?.originFileObj),
                ]).then((res) => {
                  dataToUpload?.push(res);
                });
              } else {
                postInfo?.assets
                  ?.filter(
                    (asset) => asset?.location?.key === imageObject?.name,
                  )
                  ?.map((asset) =>
                    assets?.push({
                      key: imageObject?.name,
                      type: asset?.type,
                    }),
                  );
              }
            }),
          )?.then(async () => {
            await commonFileUpload({
              assets,
              dataToUpload,
              faceBookCarouselAssets,
            });
            if (
              isAccount?.linkedin &&
              includes(
                [POST_TYPE?.IMAGE, POST_TYPE?.VIDEO, POST_TYPE?.CAROUSEL],
                postType,
              )
            ) {
              await Promise?.all(
                assets?.map(async (asset) => {
                  await handleLinkedInUploadImage(asset, linkedInAssets);
                }),
              );
            }
            await submitMediaStatus(
              ids,
              values,
              assets,
              linkedInAssets,
              faceBookCarouselAssets,
            );
          });
        } else if (postData && postInfo?.assets?.length > 0) {
          await postInfo?.assets?.map((asset) =>
            assets?.push({
              key: asset?.location?.key,
              type: asset?.type,
            }),
          );
          if (
            postInfo?.accountsData?.filter(
              (account) => account?.platform === MEDIA_NAME?.FACEBOOK,
            ).length > 0
          ) {
            await postInfo?.faceBookCarouselAssets?.map((asset) =>
              faceBookCarouselAssets?.push({
                key: asset?.location?.key,
                type: asset?.type,
              }),
            );
          }
          if (
            postInfo?.accountsData?.filter(
              (account) => account?.platform === MEDIA_NAME?.LINKEDIN,
            ).length > 0
          ) {
            await postInfo?.assets?.map((asset) =>
              linkedInAssets?.push({
                key: asset?.linkedInAssets,
                platformId: postInfo?.accounts?.platformId,
              }),
            );
          }
          await submitMediaStatus(
            ids,
            values,
            assets,
            linkedInAssets,
            faceBookCarouselAssets,
          );
        } else if (values?.imageUrl?.file?.status === 'removed') {
          dataToSend.imageUrl = null;
        } else {
          delete dataToSend.imageUrl;
        }
      } else {
        submitMediaStatus(
          ids,
          values,
          assets,
          linkedInAssets,
          faceBookCarouselAssets,
        );
      }
      setBtnLoading(false);
    } catch (error) {
      setBtnLoading(false);
    }
  };

  const handleDateSelection = (e) => {
    setPlanned(e?.target?.checked);
  };

  const disabledDate = (current) =>
    current && current <= dayjs()?.startOf('day');

  const disabledDateTime = (current) => {
    const now = dayjs();
    if (
      dayjs(current)?.format(defaultDateFormat) ===
      dayjs()?.format(defaultDateFormat)
    ) {
      return {
        disabledHours: () =>
          Array?.from({ length: now?.hour() })?.map((_, i) => i),
        disabledMinutes: (hour) =>
          hour === now?.hour()
            ? Array?.from({ length: now?.minute() + 1 })?.map((_, i) => i)
            : [],
        disabledSeconds: (hour, minute) =>
          hour === now?.hour() && minute === now?.minute()
            ? Array?.from({ length: now?.second() + 1 })?.map((_, i) => i)
            : [],
      };
    }
  };

  const checkDefaultDatePickerValue = (rule, value) => {
    if (
      (!value && !scheduledAt) ||
      dayjs(value || scheduledAt).isAfter(dayjs(), 'day') ||
      dayjs(value || scheduledAt).hour() > dayjs().hour() ||
      dayjs(value || scheduledAt).minute() > dayjs().minute()
    ) {
      return Promise.resolve();
    }
    return Promise.reject(new Error('Please select the proper schedule time'));
  };

  const publish = (status) => {
    if (status) {
      setPostStatus(status);
    }
    if (includes([MEDIA_STATUS?.PUBLISHED, MEDIA_STATUS?.SCHEDULED], status)) {
      form
        .validateFields(['socialmedia', 'postType', 'imageUrl', 'description'])
        .then(() => {
          form.submit();
        });
    } else if (includes([MEDIA_STATUS?.DRAFT], status)) {
      form.validateFields(['socialmedia']).then(() => {
        form.submit();
      });
    } else {
      form.submit();
    }
  };

  const handleEmojiPickup = (emojiObject) => {
    // eslint-disable-next-line no-undef
    const textarea = document?.getElementById('description-textarea');
    const cursorPos = textarea?.selectionStart;
    const textBeforeCursor = textarea?.value.substring(0, cursorPos);
    const textAfterCursor = textarea?.value.substring(cursorPos);
    const newText = `${textBeforeCursor}${emojiObject?.native}${textAfterCursor}`;
    textarea.value = newText;
    const newCursorPos = cursorPos + emojiObject?.native?.length;
    textarea.setSelectionRange(newCursorPos, newCursorPos);
    form?.setFieldValue('description', newText);
    textarea?.focus();
  };

  const handleDatepicker = (date) => {
    setScheduledAt(dayjs(date)?.local()?.format());
  };

  const deletePost = () => {
    updatePost({
      variables: {
        where: {
          id: postId,
        },
        data: {
          isDelete: true,
        },
      },
    });
  };

  const avatarViewer = (avatarSrc, avatarAlt) => (
    <Avatar className="post-avatar" alt={avatarAlt} src={avatarSrc} />
  );

  useEffect(() => {
    if (showPrompt) {
      dispatch({
        type: 'SET_SHOW_PROMPT',
        data: true,
      });
    }
  }, [showPrompt]);

  const handleShowPrompt = () => {
    setShowPrompt(true);
  };

  const mediaPreviewSelection = socialName?.map((item) => {
    const data = accountData?.listAccounts?.data?.find(
      (profile) => item?.[profile?.id],
    );
    return (
      <div
        key={data?.id}
        className={`selection-icon mr-0 ${
          data?.id === postPreview?.id &&
          postPreview?.platform === MEDIA_NAME?.[data?.platform] &&
          'active-selection'
        }`}
      >
        <Avatar
          onClick={() =>
            setPostPreview({
              id: data?.id,
              platform: MEDIA_NAME?.[data?.platform],
            })
          }
          alt={data?.descriptor}
          src={data?.avatar || data?.profileUrl || imagePlaceHolder}
        />
        <Image preview={false} src={getPlatformIconImage(data?.platform)} />
      </div>
    );
  });

  const handleBack = () => {
    history?.goBack();
  };

  const handleModalOk = () => {
    if (isCancel) {
      form?.resetFields();
      history?.push(ROUTES?.LIBRARY);
    } else {
      dispatch({
        type: 'SET_SHOW_PROMPT',
        data: false,
      });
      setShowPrompt(false);
      deletePost();
    }
  };

  const handleUploadButton = () => {
    if (
      includes([POST_TYPE?.CAROUSEL], postType || postInfo?.postType) &&
      fileList?.length >= 10
    ) {
      return null;
    }
    if (
      includes(
        [POST_TYPE?.IMAGE, POST_TYPE?.VIDEO],
        postType || postInfo?.postType,
      ) &&
      fileList?.length >= 1
    ) {
      return null;
    }
    return uploadButton;
  };

  const maxCharacterLength = useMemo(() => {
    if (uniq(flatMap(socialName, Object.values))?.length > 1) {
      return min(Object.values(CHARACTER_LIMIT));
    }
    if (isAccount?.facebook) {
      return CHARACTER_LIMIT?.FACEBOOK;
    }
    if (isAccount?.instagram) {
      return CHARACTER_LIMIT?.INSTAGRAM;
    }
    if (isAccount?.linkedin) {
      return CHARACTER_LIMIT?.LINKEDIN;
    }
  }, [socialName]);

  const customError = () =>
    Object.entries(errors).map(([fileName, errorMessages]) => (
      <div key={fileName} className="custom-error">
        <p>
          File: {fileName} <br />
          {errorMessages.map((errorMessage) => (
            <>
              -{errorMessage} <br />
            </>
          ))}
        </p>
      </div>
    ));

  if (accountLoading || postLoading || btnLoading) return <LoaderComponent />;

  return (
    <>
      {accountData?.listAccounts?.data?.length > 0 ? (
        <>
          <div className="display">
            <div className="post-content">
              <Title
                className="site-page-header p-0 mb-8 mt-0 create-post-header"
                level={3}
              >
                {postId ? (
                  <>
                    <Button
                      type="text"
                      shape="square"
                      onClick={handleBack}
                      icon={<ArrowLeftOutlined />}
                    />
                    <Divider type="vertical" />
                    Edit Post
                  </>
                ) : (
                  <Space>
                    Create Post
                    {!postId && (
                      <Button
                        type="primary"
                        onClick={() => history?.push(ROUTES?.ACCOUNTS)}
                      >
                        <LinkOutlined />
                        Connect Account
                      </Button>
                    )}
                  </Space>
                )}
              </Title>
              <div className="card-body-wrapper">
                <div className="video-post-alert">
                  <Alert
                    message="Videos in carousel only supported for Instagram."
                    type="warning"
                    showIcon
                  />
                </div>
                <Form
                  form={form}
                  className="sticky-action-form"
                  layout="vertical"
                  disabled={uploadLoading}
                  onValuesChange={() => setIsFieldsChanged(true)}
                  onFieldsChange={handleShowPrompt}
                  onFinish={onFinish}
                >
                  <Row gutter={16}>
                    <Col sm={24} md={12} lg={12}>
                      <div className="social-media-select">
                        <Form.Item
                          label="Select account"
                          name="socialmedia"
                          rules={[
                            {
                              required: true,
                              message: 'Please select account!',
                            },
                          ]}
                        >
                          <Select
                            mode="multiple"
                            showSearch
                            showArrow
                            allowClear
                            maxTagCount="responsive"
                            disabled={postId}
                            placeholder="Select account"
                            onChange={handleSocialOption}
                          >
                            {accountData?.listAccounts?.data?.map((account) => (
                              <Option
                                key={account?.id}
                                value={account?.id}
                                data-platform={account?.platform}
                              >
                                <div className="select-media-icons">
                                  <>
                                    <Image
                                      preview={false}
                                      src={getPlatformIconImage(
                                        account?.platform,
                                      )}
                                    />
                                    {account?.descriptor}
                                  </>
                                </div>
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </div>
                    </Col>
                    <Col sm={24} md={12} lg={12}>
                      <div className="post-type-select">
                        <Form.Item
                          label="Select media type"
                          name="postType"
                          rules={[
                            {
                              required:
                                includes(
                                  [
                                    MEDIA_STATUS?.PUBLISHED,
                                    MEDIA_STATUS?.SCHEDULED,
                                  ],
                                  postStatus,
                                ) && checked,
                              message: 'Please select media type!',
                            },
                            () => ({
                              validator(_, value) {
                                if (
                                  isAccount?.instagram &&
                                  includes(
                                    [POST_TYPE?.TEXT, POST_TYPE?.LINK],
                                    value,
                                  )
                                ) {
                                  return Promise?.reject(
                                    new Error(
                                      'Text and Link post is not available on Instagram!',
                                    ),
                                  );
                                }
                                return Promise?.resolve();
                              },
                            }),
                          ]}
                        >
                          <Select
                            value={postType}
                            placeholder="Select post type"
                            options={postTypeOption}
                            onChange={handlePostOption}
                            disabled={socialName?.length < 0}
                          />
                        </Form.Item>
                      </div>
                    </Col>
                    {includes(
                      [POST_TYPE?.IMAGE, POST_TYPE?.VIDEO, POST_TYPE?.CAROUSEL],
                      postType || postInfo?.postType,
                    ) && (
                      <Col xs={24}>
                        <Form.Item
                          name="imageUrl"
                          dependencies={['socialmedia']}
                        >
                          <Upload
                            listType="picture-card"
                            accept={
                              postType === POST_TYPE?.IMAGE
                                ? ALLOWED_IMAGES_TYPE
                                : ALLOWED_TYPE_CAROUSEL
                            }
                            fileList={fileList}
                            onChange={handleChange}
                            beforeUpload={() => false}
                            showUploadList={{ showPreviewIcon: false }}
                          >
                            {handleUploadButton()}
                          </Upload>
                        </Form.Item>
                        {Object?.keys(errors)?.length > 0 && customError()}
                        {postType === POST_TYPE?.VIDEO && (
                          <div className="video-post-alert">
                            <Alert
                              message="You can only post video of 'mp4' format!"
                              type="info"
                              showIcon
                            />
                          </div>
                        )}
                      </Col>
                    )}
                    <Col xs={24}>
                      <Form.Item
                        label="Description"
                        name="description"
                        rules={[
                          {
                            required: includes(
                              [
                                MEDIA_STATUS?.PUBLISHED,
                                MEDIA_STATUS?.SCHEDULED,
                              ],
                              postStatus,
                            ),
                            message: 'Please enter description!',
                          },
                        ]}
                      >
                        <TextArea
                          showCount
                          maxLength={maxCharacterLength}
                          id="description-textarea"
                          className="input-emoji"
                          placeholder="Description"
                          onChange={handleDescription}
                          rows={5}
                          ref={inputRef}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24}>
                      <Form.Item>
                        <Space>
                          <div className="input-emoji">
                            <SmileOutlined
                              id="emoji-picker-icon"
                              onClick={() => setEmojiPicker(true)}
                            />
                            {emojiPicker && (
                              <div className="emoji-picker">
                                <Picker
                                  onEmojiSelect={handleEmojiPickup}
                                  onClickOutside={() => {
                                    if (
                                      // eslint-disable-next-line no-undef
                                      document.activeElement.id !==
                                        'description-textarea' &&
                                      // eslint-disable-next-line no-undef
                                      document.activeElement.id !==
                                        'emoji-picker-icon'
                                    ) {
                                      setEmojiPicker(false);
                                    }
                                  }}
                                  title="Pick your emoji"
                                  previewPosition="none"
                                  skinTonePosition="none"
                                  theme="light"
                                />
                              </div>
                            )}
                          </div>
                          <Space size={[0, 8]} wrap>
                            <Space size={[0, 8]} wrap>
                              {tags.map((tag, index) => {
                                if (editInputIndex === index) {
                                  return (
                                    <Input
                                      ref={editInputRef}
                                      key={tag}
                                      size="small"
                                      style={tagInputStyle}
                                      value={editInputValue}
                                      onChange={handleEditInputChange}
                                      onBlur={handleEditInputConfirm}
                                      onPressEnter={handleEditInputConfirm}
                                    />
                                  );
                                }
                                const tagElem = (
                                  <Tag
                                    key={tag}
                                    closable
                                    onClose={() => handleClose(tag)}
                                  >
                                    <span>{tag}</span>
                                  </Tag>
                                );
                                return tagElem;
                              })}
                            </Space>
                            {inputVisible ? (
                              <Input
                                ref={inputRef}
                                type="text"
                                size="small"
                                style={tagInputStyle}
                                value={inputValue}
                                onChange={handleInputChange}
                                onBlur={handleInputConfirm}
                                onPressEnter={handleInputConfirm}
                              />
                            ) : (
                              <Tag style={tagPlusStyle} onClick={showInput}>
                                <PlusOutlined /> Add New Tag
                              </Tag>
                            )}
                          </Space>
                        </Space>
                      </Form.Item>
                    </Col>
                    <Col xs={24}>
                      <Form.Item name="check">
                        <Checkbox
                          checked={planned}
                          onChange={handleDateSelection}
                        >
                          If you want to schedule post click here
                        </Checkbox>
                      </Form.Item>
                    </Col>
                    {planned && (
                      <Col xs={24}>
                        <Form.Item
                          name="datepicker"
                          rules={[
                            {
                              validator: checkDefaultDatePickerValue,
                            },
                          ]}
                        >
                          <DatePicker
                            showTime
                            disabledDate={disabledDate}
                            disabledTime={disabledDateTime}
                            onChange={handleDatepicker}
                            use12Hours
                            defaultValue={
                              scheduledAt ? dayjs(scheduledAt) : dayjs()
                            }
                          />
                        </Form.Item>
                      </Col>
                    )}
                  </Row>
                </Form>
                <div className="buttons-footer-wrapper">
                  {postId && (
                    <>
                      <Button
                        danger
                        type="primary"
                        disabled={uploadLoading || btnLoading}
                        onClick={() => setModalOpen(true)}
                      >
                        <DeleteOutlined />
                        Delete
                      </Button>
                    </>
                  )}
                  <Button
                    type="primary"
                    loading={loading}
                    disabled={
                      Object.entries(errors)?.length > 0 ||
                      uploadLoading ||
                      planned ||
                      !isFieldsChanged ||
                      btnLoading
                    }
                    onClick={() => publish(MEDIA_STATUS?.PUBLISHED)}
                  >
                    Publish
                  </Button>
                  <Button
                    type="primary"
                    loading={scheduleLoading}
                    disabled={
                      Object.entries(errors)?.length > 0 ||
                      uploadLoading ||
                      !planned ||
                      !isFieldsChanged ||
                      btnLoading
                    }
                    onClick={() => {
                      if (postId) {
                        publish();
                      } else {
                        publish(MEDIA_STATUS?.SCHEDULED);
                      }
                    }}
                  >
                    {postId ? 'Reschedule' : 'Schedule'}
                  </Button>
                  <Button
                    type="dashed"
                    loading={updateLoading}
                    disabled={
                      Object.entries(errors)?.length > 0 ||
                      uploadLoading ||
                      planned ||
                      !isFieldsChanged ||
                      btnLoading
                    }
                    onClick={() => {
                      if (postId) {
                        publish();
                      } else {
                        publish(MEDIA_STATUS?.DRAFT);
                      }
                    }}
                  >
                    <InboxOutlined />
                    {includes([MEDIA_STATUS?.DRAFT], postInfo?.status)
                      ? 'Save'
                      : 'Save as Draft'}
                  </Button>
                  <Button
                    danger
                    disabled={uploadLoading || btnLoading}
                    onClick={() => {
                      dispatch({
                        type: 'SET_SHOW_PROMPT',
                        data: false,
                      });
                      setShowPrompt(false);
                      if (isFieldsChanged) {
                        setModalOpen(true);
                        setIsCancel(true);
                      } else {
                        history?.push(ROUTES?.LIBRARY);
                      }
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </div>
            <div className="post-preview">
              <div className="post-preview-header mb-16">
                <div className="media-selection-icons pointer">
                  {mediaPreviewSelection}
                </div>
                <div className="preview-switch">
                  <span>Preview:</span>
                  <Switch checked={checked} onChange={handlePreviewSwitch} />
                </div>
              </div>
              {checked ? (
                <>
                  <div className="post-priview-container">
                    {postPreview?.platform === MEDIA_NAME?.INSTAGRAM &&
                      !includes(
                        [POST_TYPE?.TEXT, POST_TYPE?.LINK],
                        postType,
                      ) && (
                        <>
                          <InstagramLayout
                            instagramUserName={userName?.INSTAGRAM}
                            instagramAvatar={avatar?.INSTAGRAM}
                            description={description}
                            videoSource={videoSource}
                            postData={postData}
                            headerImageUrl={headerImageUrl}
                            avatarViewer={avatarViewer}
                          />
                        </>
                      )}
                    {postPreview?.platform === MEDIA_NAME?.FACEBOOK && (
                      <>
                        <FacebookLayout
                          facebookUserName={userName?.FACEBOOK}
                          facebookAvatar={avatar?.FACEBOOK}
                          description={description}
                          videoSource={videoSource}
                          headerImageUrl={headerImageUrl}
                          avatarViewer={avatarViewer}
                        />
                      </>
                    )}
                    {postPreview?.platform === MEDIA_NAME?.LINKEDIN && (
                      <>
                        <LinkedInLayout
                          linkedinUserName={userName?.LINKEDIN}
                          linkedinAvatar={avatar?.LINKEDIN}
                          description={description}
                          videoSource={videoSource}
                          headerImageUrl={headerImageUrl}
                          avatarViewer={avatarViewer}
                        />
                      </>
                    )}
                  </div>
                  <div className="preview-note">
                    It's important to note that the post previews are not a
                    perfect representation of the final published version. We
                    make every effort to ensure accuracy, but please understand
                    that there may be slight variations.
                  </div>
                </>
              ) : (
                <div className="no-preview-text">
                  <Empty
                    description="  Preview is disabled. Please enable it to see how your post will
                look like."
                  />
                </div>
              )}
            </div>
          </div>
          <Modal
            centered
            closable={false}
            open={modalOpen}
            onOk={handleModalOk}
            onCancel={() => {
              setModalOpen(false);
              setIsCancel(false);
            }}
          >
            {isCancel ? (
              <p className="m-0">
                Are you sure you want to cancel? Your changes will be lost.
              </p>
            ) : (
              <p className="m-0">
                Are you sure you want to permanently delete this post from the
                Sharehive platform?
              </p>
            )}
          </Modal>
        </>
      ) : (
        <Space className="no-accounts-linked">
          <img src={SocialPostIllustration} alt="" />
          <h2>
            No accounts have been linked yet. Please{' '}
            <Link to={ROUTES?.ACCOUNTS}>click here</Link> to connect your social
            media account first.
          </h2>
        </Space>
      )}
    </>
  );
}

export default Post;
