import { useMutation } from '@apollo/client';
import { Button, Card, Divider, Form, Input, message, Space, Spin } from 'antd';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { GoogleLoginButton } from 'react-social-login-buttons';
import { AppContext } from '../../AppContext';
import { ROUTES } from '../../common/constants';
import { formValidatorRules } from '../../common/utils';
import { signIn } from '../../firebase';
import { GOOGLE_LOGIN, SIGNUP } from './graphql/Mutations';

const { required, email } = formValidatorRules;

const Signup = () => {
  const history = useHistory();
  const { initializeAuth } = useContext(AppContext);

  const [signupMutate, { loading: signupLoading }] = useMutation(SIGNUP, {
    onError() {},
  });

  const [GoogleLoginMutate, { data: googleData }] = useMutation(GOOGLE_LOGIN, {
    onError() {},
  });

  function successCallback(accessToken, userData, refreshToken, emailVerified) {
    initializeAuth(accessToken, userData, refreshToken, emailVerified);
    if (!emailVerified) {
      history?.replace(ROUTES.EMAIL_VERIFICATION);
    } else {
      history?.replace(ROUTES.MAIN);
    }
  }

  const onFinish = async (values) => {
    try {
      const formValues = {
        email: values?.email?.trim().toLowerCase(),
        firstName: values?.firstName?.trim(),
        lastName: values?.lastName?.trim(),
        password: values?.password?.trim(),
      };
      const response = await signupMutate({
        variables: {
          data: { ...formValues, authProvider: 'CUSTOM' },
        },
      });
      if (response?.data) {
        const accessToken = response?.data?.signUp?.data?.accessToken;
        const userData = response?.data?.signUp?.data;
        const tokenRefresh = response?.data?.signUp?.data?.refreshToken;
        const emailVerified = response?.data?.signUp?.data?.emailVerified;
        if (successCallback) {
          successCallback(accessToken, userData, tokenRefresh, emailVerified);
        }
      }
    } catch (error) {
      if (error?.message) {
        message?.error(error?.message);
      } else {
        message?.error('Something went wrong');
      }
    }
  };

  const googleLogin = async (res) => {
    try {
      await GoogleLoginMutate({
        variables: {
          data: {
            token: `Bearer ${res?.[0]}`,
          },
        },
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console?.error('error from login => ', error);
    }
  };

  if (googleData) {
    const accessToken = googleData?.googleSignIn?.data?.accessToken;
    const userData = googleData?.googleSignIn?.data?.user;
    const tokenRefresh = googleData?.googleSignIn?.data?.refreshToken;
    const emailVerified = googleData?.googleSignIn?.data?.user?.emailVerified;
    if (successCallback) {
      successCallback(accessToken, userData, tokenRefresh, emailVerified);
    }
  }

  return (
    <div className="auth-bg">
      <div className="login-wrap d-flex align-center justify-start">
        <Card className="full-width">
          <Spin spinning={signupLoading} wrapperClassName="full-width">
            <Space className="logo">
              <img alt="logo" src="/logo.png" />
              Share Hive
            </Space>
            <GoogleLoginButton
              onClick={async () => {
                const res = await signIn();
                googleLogin(res);
              }}
            >
              Continue with Google
            </GoogleLoginButton>
            <Divider>OR</Divider>
            <Form
              name="Signup"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              size="large"
            >
              <Form.Item
                name="firstName"
                rules={[{ required, message: 'Please enter name!' }]}
              >
                <Input placeholder="Enter firstname" />
              </Form.Item>
              <Form.Item name="lastName">
                <Input placeholder="Enter lastname" />
              </Form.Item>
              <Form.Item
                name="email"
                rules={[{ required, message: 'Please enter email!' }, email]}
              >
                <Input placeholder="Email address" />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[{ required, message: 'Please enter password!' }]}
              >
                <Input.Password placeholder="Password" />
              </Form.Item>
              <Form.Item className="full-width mb-8">
                <Button type="primary" className="full-width" htmlType="submit">
                  Signup
                </Button>
              </Form.Item>
              <Form.Item className="text-center mb-0">
                <Space>
                  Already have an account ?
                  <Button
                    type="link"
                    className="p-0"
                    onClick={() => {
                      history?.push(ROUTES?.LOGIN);
                    }}
                  >
                    Login
                  </Button>
                </Space>
              </Form.Item>
            </Form>
          </Spin>
        </Card>
        <div className="text-center login-copyrights">
          Share Hive © {new Date().getFullYear()}
        </div>
      </div>
    </div>
  );
};

export default Signup;
