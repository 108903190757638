import { MailOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Card, Input, Space, Spin } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import OTPInput from 'react-otp-input';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import {
  EMAIL_VERIFICATION_LOCKED_FOR,
  EMAIL_VERIFIED,
  LOCK,
  ROUTES,
} from '../../common/constants';
import './auth.less';
import {
  EMAIL_VERIFICATION,
  RESEND_VERIFICATION_CODE,
} from './graphql/Mutations';

const VerifyEmail = () => {
  const { getCurrentUser } = useContext(AppContext);
  const { email } = getCurrentUser();
  const history = useHistory();
  const [otp, setOtp] = useState();
  const [btnLoading, setBtnLoading] = useState(false);

  const [emailVerification, { loading }] = useMutation(EMAIL_VERIFICATION, {
    onError: () => {},
    onCompleted: () => {
      // eslint-disable-next-line no-undef
      localStorage?.setItem(EMAIL_VERIFIED, true);
      history?.replace(ROUTES?.MAIN);
    },
  });
  const [resendCode] = useMutation(RESEND_VERIFICATION_CODE, {
    onError: () => {},
  });

  const verify = () => {
    emailVerification({
      variables: {
        data: {
          verificationCode: otp,
        },
      },
    });
  };

  const useLocalData = () => {
    const [nextAttemptTime, setNextAttemptTime] = useState(
      // eslint-disable-next-line no-undef
      localStorage?.getItem(EMAIL_VERIFICATION_LOCKED_FOR),
    );
    // eslint-disable-next-line no-undef
    const lock = JSON?.parse(localStorage?.getItem(LOCK));
    // eslint-disable-next-line no-undef
    const attempt = localStorage?.getItem(EMAIL_VERIFICATION_LOCKED_FOR);

    useEffect(() => {
      setBtnLoading(lock);
      setNextAttemptTime(attempt);
    }, [lock, attempt]);
    return { nextAttemptTime };
  };

  const { nextAttemptTime } = useLocalData();

  useEffect(() => {
    const interval = setInterval(() => {
      if (new Date(nextAttemptTime) <= new Date(Date.now())) {
        clearInterval(interval);
        // eslint-disable-next-line no-undef
        localStorage?.removeItem(LOCK);
        // eslint-disable-next-line no-undef
        localStorage?.removeItem(EMAIL_VERIFICATION_LOCKED_FOR);
        setBtnLoading(false);
      }
    }, 1000);
  }, [nextAttemptTime]);

  const handleSignup = () => {
    // eslint-disable-next-line no-undef
    localStorage?.clear();
    history?.push(ROUTES?.SIGNUP);
  };
  const handleLogin = () => {
    // eslint-disable-next-line no-undef
    localStorage?.clear();
    history?.push(ROUTES?.LOGIN);
  };
  const handleResendCode = () => {
    resendCode();
  };

  return (
    <div className="auth-bg">
      <div className="login-wrap d-flex align-center justify-start">
        <Card className="full-width">
          <Spin spinning={loading} wrapperClassName="full-width">
            <div className="email-verification">
              <div className="text-center">
                <div id="logo">Share Hive</div>
                <h2 className="text-center mt-8">
                  Verify Your Email <MailOutlined />
                </h2>
                <p className="text-center">
                  {`Please enter 6 digit code sent to your email ${email}, to continue.`}
                </p>
              </div>
              <div className="otp-input">
                <Space>
                  <OTPInput
                    value={otp}
                    onChange={setOtp}
                    numInputs={6}
                    renderSeparator={<span />}
                    renderInput={(props) => <Input {...props} />}
                  />
                </Space>
              </div>
              {btnLoading ? (
                <p className="text-center">
                  Maximum attempt reached, Please try again after 10 minutes.
                </p>
              ) : (
                <p className="text-center">
                  Didn't receive email?
                  <Button type="link" onClick={handleResendCode}>
                    Resend Email
                  </Button>
                </p>
              )}
              <div className=" full-width mb-8">
                <Button
                  type="primary"
                  className="full-width"
                  onClick={verify}
                  disabled={btnLoading}
                >
                  Verify
                </Button>
                <p className="text-center mb-0">
                  Register with different email address.
                  <Button type="link" onClick={handleSignup}>
                    Sign up here
                  </Button>
                </p>
                <p className="text-center m-0">
                  Already have an account?
                  <Button type="link" onClick={handleLogin}>
                    Login
                  </Button>
                </p>
              </div>
            </div>
          </Spin>
        </Card>
        <div className="text-center login-copyrights">
          Share Hive © {new Date().getFullYear()}
        </div>
      </div>
    </div>
  );
};

export default VerifyEmail;
