import {
  DownOutlined,
  ExclamationCircleOutlined,
  IdcardOutlined,
  LoginOutlined,
} from '@ant-design/icons';
import { Avatar, Dropdown, Modal, Space } from 'antd';
import React, { useContext, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import { ROUTES } from '../../../common/constants';
import history from '../../../historyData';

const UserProfile = () => {
  const { getCurrentUser } = useContext(AppContext);
  const { pathname } = useLocation();
  const { firstName = '', lastName = '', displayName = '', avatar = '' } =
    getCurrentUser() || {};
  const [logoutModal, setLogoutModal] = useState(false);

  const handleLogout = () => {
    if (!logoutModal) {
      setLogoutModal(true);
      Modal?.confirm({
        icon: <ExclamationCircleOutlined />,
        title: 'Logout',
        content: 'Are you sure you want to logout?',
        centered: true,
        className: 'custom-modal-button',
        onOk: () => {
          history.push(ROUTES?.LOGOUT);
          setLogoutModal(false);
        },
        onCancel: () => {
          setLogoutModal(false);
        },
      });
    }
  };
  const items = [
    {
      key: 'profile',
      label: <Link to={ROUTES?.PROFILE}>My Profile</Link>,
      icon: <IdcardOutlined />,
    },
    {
      key: 'logout',
      label: <div onClick={handleLogout}>Logout</div>,
      icon: <LoginOutlined />,
    },
  ];
  const nameFirstCharacter = displayName?.[0];
  const nameLastCharacter = displayName
    ?.split(' ')
    ?.splice(-1)
    ?.map((character) => character?.slice(0, 1))
    ?.join('')
    ?.toLocaleUpperCase();

  return (
    <Dropdown
      menu={{ items, selectedKeys: [`${pathname?.split('/')?.[1]}`] }}
      trigger={['click']}
    >
      <Space className="pointer d-flex align-center gap-4" size="small">
        {displayName?.length ? (
          <>
            <Avatar alt="Avatar">
              {nameFirstCharacter}
              {nameLastCharacter}{' '}
            </Avatar>
            <span className="m-hide">
              {`${displayName.split(' ', 1)} ${displayName
                ?.split(' ')
                ?.slice(-1)}`}
            </span>
          </>
        ) : (
          <>
            {!avatar ? (
              <>
                <Avatar alt="Avatar">{`${firstName?.[0]?.toLocaleUpperCase()}${lastName?.[0]?.toLocaleUpperCase()}`}</Avatar>
                <span className="m-hide">
                  {`${firstName} ${lastName && lastName}`}{' '}
                </span>
              </>
            ) : (
              <>
                <Avatar alt="Avatar" src={avatar?.url} />
                <span className="m-hide">
                  {`${firstName} ${lastName && lastName}`}{' '}
                </span>
              </>
            )}
          </>
        )}
        <DownOutlined />
      </Space>
    </Dropdown>
  );
};

export default UserProfile;
