import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const GET_ACCOUNTS = gql`
  query listAccounts($filter: AccountFilter) {
    listAccounts(filter: $filter) {
      data {
        id
        userId
        createdAt
        firstName
        lastName
        platform
        platformId
        platformType
        avatar
        profileUrl
        descriptor
        accessToken
        isDisconnected
      }
    }
  }
`;
