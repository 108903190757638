import { useMutation } from '@apollo/client';
import { Button, Card, Form, Input, Space, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import OTPInput from 'react-otp-input';
import { useHistory } from 'react-router-dom';
import {
  EMAIL,
  EMAIL_VERIFICATION_LOCKED_FOR,
  LOCK,
  ROUTES,
} from '../../common/constants';
import { formValidatorRules } from '../../common/utils';
import { RESET_PASSWORD } from './graphql/Mutations';

const { required } = formValidatorRules;

const ChangePassword = () => {
  const history = useHistory();
  const [otp, setOtp] = useState();
  const [btnLoading, setBtnLoading] = useState(false);
  // eslint-disable-next-line no-undef
  const email = localStorage?.getItem(EMAIL);

  const [resetPassword, { loading: resetting }] = useMutation(RESET_PASSWORD, {
    onCompleted: () => {
      // eslint-disable-next-line no-undef
      localStorage?.removeItem(EMAIL);
    },
  });
  const useLocalData = () => {
    const [nextAttemptTime, setNextAttemptTime] = useState(
      // eslint-disable-next-line no-undef
      localStorage?.getItem(EMAIL_VERIFICATION_LOCKED_FOR),
    );
    // eslint-disable-next-line no-undef
    const lock = JSON.parse(localStorage?.getItem(LOCK));
    // eslint-disable-next-line no-undef
    const attempt = localStorage?.getItem(EMAIL_VERIFICATION_LOCKED_FOR);

    useEffect(() => {
      setBtnLoading(lock);
      setNextAttemptTime(attempt);
    }, [lock, attempt]);

    return { nextAttemptTime };
  };

  const { nextAttemptTime } = useLocalData();

  useEffect(() => {
    const interval = setInterval(() => {
      if (new Date(nextAttemptTime) <= new Date(Date.now())) {
        clearInterval(interval);
        // eslint-disable-next-line no-undef
        localStorage?.removeItem(LOCK);
        // eslint-disable-next-line no-undef
        localStorage?.removeItem(EMAIL_VERIFICATION_LOCKED_FOR);
        setBtnLoading(false);
      }
    }, 1000);
  }, [nextAttemptTime]);

  const onFinish = async ({ password }) => {
    const response = await resetPassword({
      variables: { data: { otp, newPassword: password?.trim(), email } },
    });
    if (response) {
      history?.replace(ROUTES?.LOGIN);
    }
  };

  return (
    <div className="auth-bg">
      <div className="login-wrap d-flex align-center justify-center">
        <Card className="full-width">
          <div className="text-center">
            <div id="logo">Share Hive</div>
            <h2 className="text-center">Reset Password</h2>
            <p className="text-center">Enter a new password for your account</p>
          </div>
          <Spin spinning={resetting}>
            <Form name="reset-password" onFinish={onFinish} size="large">
              <Form.Item
                name="otp"
                rules={[{ required, message: 'Please enter OTP!' }]}
              >
                <div className="otp-input">
                  <Space>
                    <OTPInput
                      value={otp}
                      onChange={setOtp}
                      numInputs={6}
                      renderSeparator={<span />}
                      renderInput={(props) => <Input {...props} />}
                    />
                  </Space>
                </div>
              </Form.Item>
              <Form.Item
                name="password"
                rules={[{ required, message: 'Please enter new password!' }]}
                label="Password"
              >
                <Input.Password placeholder="Enter New Password" />
              </Form.Item>
              {btnLoading && (
                <p className="text-center">
                  Maximum attempt reached, Please try again after 10 min
                </p>
              )}
              <Form.Item className="full-width mb-8">
                <Button
                  type="primary"
                  className="full-width"
                  htmlType="submit"
                  disabled={btnLoading}
                >
                  Reset Password
                </Button>
              </Form.Item>
              <Form.Item className="text-center mb-0">
                <Button
                  type="link"
                  onClick={() => {
                    history?.push(ROUTES?.LOGIN);
                  }}
                >
                  Cancel
                </Button>
              </Form.Item>
            </Form>
          </Spin>
        </Card>
      </div>
    </div>
  );
};

export default ChangePassword;
