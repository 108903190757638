import dayjs from 'dayjs';

/* ROUTERS  */
export const ROUTES = {
  MAIN: '/',
  LOGOUT: '/logout',
  SIGNUP: '/signup',
  LOGIN: '/login',
  EMAIL_VERIFICATION: '/verification',
  FORGET_PASSWORD: '/forgot-password',
  RESET: '/reset-password',
  AUTHENTICATION: '/authentication',
  PROFILE: '/profile',
  ACCOUNTS: '/accounts',
  CALENDAR: '/calendar',
  POST: '/post',
  LIBRARY: '/library',
};

/*  Modules */
export const MODULES = {
  CALENDAR: 'Calendar',
  POST: 'Post',
  ACCOUNTS: 'Accounts',
  LIBRARY: 'Library',
  INSTAGRAM: 'Instagram',
  FACEBOOK: 'Facebook',
  LINKEDIN: 'Linkedin',
};
export const STATUS = {
  PUBLISHED: 'PUBLISHED',
  SCHEDULED: 'SCHEDULED',
  DRAFT: 'DRAFT',
  FAILED: 'FAILED',
};

export const CALENDAR_MODULE = 'CALENDAR';

/* Authentication */
export const TOKEN = 'TOKEN';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const USER = 'USER';
export const EMAIL_VERIFIED = 'EMAIL_VERIFIED';
export const EMAIL = 'EMAIL';
export const REAUTHENTICATION = 'REAUTHENTICATION';
export const NETWORK = 'NETWORK';
export const EMAIL_VERIFICATION_LOCKED_FOR = 'EMAIL_VERIFICATION_LOCKED_FOR';
export const LOCK = 'LOCK';

export const SHAREHIVE = 'SHAREHIVE';

export const ROLES = {
  SUPER_ADMIN: 'Super admin',
  ADMIN: 'Admin',
};

export const ROLE_KEYS = {
  SUPER_ADMIN: 'SUPER_ADMIN',
  ADMIN: 'ADMIN',
};
/* Date and time */
export const defaultDateFormat = 'DD/MM/YYYY';
export const CUSTOM_DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm:ssZ';
export const timeFormat = 'hh:mm a';
export const backendDateFormat = 'YYYY-MM-DD';

export const REGEX = {
  NAME: /^[a-z ,.'-]+$/i,
  ZIPCODE: /^[0-9]{5,6}$/,
  CITY: /^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/,
  WEB_URL: /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi,
  PASSWORD: /^(?=.*[0-9])(?=.*[a-zA-Z])[a-zA-Z0-9!@#$%^&*]{8,16}$/,
  PHONE: /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/,
  EMAIL: /^(([^<>()[\]\\.,;:!#$*%^'`~={}?/&\s@"]+(\.[^<>()[\]\\.,;:!#$*%^'`~={}?/&\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  AMOUNT: /^\d+$|^\d+\.\d*$/,
  OPTIONALNEGATIVEAMOUNT: /^[-]?\d+$|^[-]?\d+\.\d*$/,
  NUMBER: /^\d+$/,
  COUNTRY_CODE_PHONE: /^[0-9]{7,12}$/,
};

export const LIMIT = 10;
export const SCROLL_PERCENT = 0.85;

export const ORDER = [
  { name: 'Ascending', value: 'ASC' },
  { name: 'Descending', value: 'DESC' },
];

export const GENDER = [
  { name: 'Female', value: 'FEMALE' },
  { name: 'Male', value: 'MALE' },
  { name: 'Other', value: 'OTHER' },
];

export const MEDIA_NAME = {
  INSTAGRAM: 'INSTAGRAM',
  FACEBOOK: 'FACEBOOK',
  LINKEDIN: 'LINKEDIN',
};

export const FACEBOOK_KEYS = {
  FACEBOOKPAGE: 'FACEBOOKPAGE',
  FACEBOOKGROUP: 'FACEBOOKGROUP',
};

export const PLATFORM_TYPE = {
  PAGE: 'PAGE',
  GROUP: 'GROUP',
};

export const SOCIAL_MEDIA = [
  {
    value: 'ALL',
    label: 'All',
  },
  {
    value: 'INSTAGRAM',
    label: 'Instagram',
  },
  {
    value: 'FACEBOOK',
    label: 'Facebook',
  },
  {
    value: 'LINKEDIN',
    label: 'Linkedin',
  },
];

export const CHARACTER_LIMIT = {
  FACEBOOK: 63006,
  INSTAGRAM: 2000,
  LINKEDIN: 2000,
};

export const VIDEO_LENGTH = {
  FACEBOOK: 14400,
  INSTAGRAM: 60,
  LINKEDIN: 600,
};

export const POST_TYPE = {
  IMAGE: 'IMAGE',
  VIDEO: 'VIDEO',
  TEXT: 'TEXT',
  LINK: 'LINK',
  CAROUSEL: 'CAROUSEL',
};

export const POST_TYPE_OPTIONS = [
  {
    value: 'TEXT',
    label: 'Text',
  },
  {
    value: 'LINK',
    label: 'Link',
  },
  {
    value: 'IMAGE',
    label: 'Image',
  },
  {
    value: 'VIDEO',
    label: 'Video',
  },
  {
    value: 'CAROUSEL',
    label: 'Carousel',
  },
];

export const POST_TYPE_FOR_INSTAGRAM = [
  {
    value: 'IMAGE',
    label: 'Image',
  },
  {
    value: 'VIDEO',
    label: 'Video',
  },
  {
    value: 'CAROUSEL',
    label: 'Carousel',
  },
];

export const MEDIA_TYPE_OPTION = [
  {
    value: 'all',
    label: 'All',
  },
  {
    value: 'IMAGE',
    label: 'Image',
  },
  {
    value: 'VIDEO',
    label: 'Video',
  },
  {
    value: 'CAROUSEL',
    label: 'Carousel',
  },
  {
    value: 'TEXT',
    label: 'Text',
  },
  {
    value: 'LINK',
    label: 'Link',
  },
];

export const MEDIA_TYPE_OPTION_FOR_INSTAGRAM = [
  {
    value: 'all',
    label: 'All',
  },
  {
    value: 'IMAGE',
    label: 'Image',
  },
  {
    value: 'VIDEO',
    label: 'Video',
  },
  {
    value: 'CAROUSEL',
    label: 'Carousel',
  },
];

export const EDITABLE_POST_TYPE = ['SCHEDULED', 'DRAFT', 'FAILED'];

export const MEDIA_STATUS = {
  SCHEDULED: 'SCHEDULED',
  PUBLISHED: 'PUBLISHED',
  DRAFT: 'DRAFT',
  FAILED: 'FAILED',
};

export const MEDIA_STATUS_OPTION = [
  {
    value: 'all',
    label: 'All',
  },
  {
    value: 'DRAFT',
    label: 'Draft',
  },
  {
    value: 'SCHEDULED',
    label: 'Scheduled',
  },
  {
    value: 'PUBLISHED',
    label: 'Published',
  },
  {
    value: 'FAILED',
    label: 'Failed',
  },
];
export const RANGE_PRESET = [
  {
    label: 'Last 7 Days',
    value: [dayjs().add(-7, 'd'), dayjs()],
  },
  {
    label: 'Last 14 Days',
    value: [dayjs().add(-14, 'd'), dayjs()],
  },
  {
    label: 'Last 30 Days',
    value: [dayjs().add(-30, 'd'), dayjs()],
  },
  {
    label: 'Last 90 Days',
    value: [dayjs().add(-90, 'd'), dayjs()],
  },
  {
    label: 'Current Month',
    value: [dayjs().startOf('month'), dayjs().endOf('month')],
  },
];

export const ERROR_PAGE_TITLE = 'Oops! An error occurred!';
export const ERROR_PAGE_SUBTITLE =
  'Something is broken. Please let us know what you were doing when this error occurred. We will fix it as soon as possible. Sorry for any inconvenience caused.';

export const GUTTER_VARIATIONS = { xs: 16, sm: 16, md: 24, lg: 32 };

export const SIZE = {
  xs: 24,
  sm: 32,
  md: 40,
  lg: 64,
  xl: 80,
  xxl: 100,
};

export const BREAKPOINTS = {
  mobile: 550,
  tablet: 767,
  desktop: 1000,
  hd: 1200,
};

export const ALLOWED_IMAGES_TYPE = ['image/png', 'image/jpeg'];
export const ALLOWED_TYPE_CAROUSEL = ['image/png', 'image/jpeg', 'video/mp4'];

export const POST_STATUS_COLOR = {
  PUBLISHED: 'success',
  SCHEDULED: 'processing',
  DRAFT: 'default',
  FAILED: 'error',
};
