import { UserOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Card, Form, Input, Space, Spin } from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { EMAIL, ROUTES } from '../../common/constants';
import { formValidatorRules } from '../../common/utils';
import { FORGOT_PASSWORD } from './graphql/Mutations';

const { required, email } = formValidatorRules;

const ForgetPassword = () => {
  const history = useHistory();
  const [forgetPasswordMutation, { loading }] = useMutation(FORGOT_PASSWORD, {
    onError() {},
    onCompleted() {
      history?.push(ROUTES?.RESET);
    },
  });

  const onFinish = async (values) => {
    // eslint-disable-next-line no-undef
    localStorage?.setItem(EMAIL, values?.email?.trim()?.toLowerCase());
    try {
      const formValues = {
        email: values?.email?.trim()?.toLowerCase(),
      };
      await forgetPasswordMutation({
        variables: { data: { ...formValues } },
      });
    } catch (error) {
      return error;
    }
  };
  return (
    <div className="auth-bg">
      <div className="login-wrap d-flex align-center justify-center">
        <Card className="full-width">
          <div className="text-center">
            <Space className="logo">
              <img alt="logo" src="/logo.png" />
              Share Hive
            </Space>
            <h2 className="text-center mt-8">Reset Your Password ?</h2>
            <p className="text-center">
              Please provide us with the email address linked to your account,
              and we will send you a link to reset your Password.
            </p>
          </div>
          <Spin spinning={false}>
            <Form layout="vertical" onFinish={onFinish} size="large">
              <Form.Item
                name="email"
                rules={[{ required, message: 'Please enter email!' }, email]}
              >
                <Input prefix={<UserOutlined />} placeholder="Enter email" />
              </Form.Item>
              <Form.Item className="full-width mb-8">
                <Button
                  type="primary"
                  loading={loading}
                  className="full-width"
                  htmlType="submit"
                >
                  Reset Password
                </Button>
              </Form.Item>
              <Form.Item className="text-center mb-0">
                <Button
                  type="link"
                  onClick={() => {
                    history?.push(ROUTES?.LOGIN);
                  }}
                >
                  Cancel
                </Button>
              </Form.Item>
            </Form>
          </Spin>
        </Card>
        <div className="text-center login-copyrights">
          Share Hive © {new Date().getFullYear()}
        </div>
      </div>
    </div>
  );
};

export default ForgetPassword;
