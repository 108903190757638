import { gql } from '@apollo/client';

export const REMOVE_POST = gql`
  mutation removePost(
    $removePostId: String!
    $isPlatformDelete: Boolean!
    $isMultiPlatformDelete: Boolean
  ) {
    removePost(
      id: $removePostId
      isPlatformDelete: $isPlatformDelete
      isMultiPlatformDelete: $isMultiPlatformDelete
    ) {
      message
    }
  }
`;

export const REMOVE_MULTIPLE_POST = gql`
  mutation removeMultiplePosts($data: RemoveMultiplePostsInput!) {
    removeMultiplePosts(data: $data) {
      message
    }
  }
`;
