import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Error404 from '../Error404';
import { ROUTES } from '../common/constants';
import AccountsWrapper from '../modules/accounts';
import Accounts from '../modules/accounts/Accounts';
import CalendarWrapper from '../modules/calendar';
import LibraryWrapper from '../modules/library';
import PostWrapper from '../modules/post';
import ProfileWrapper from '../modules/profile';

const ContentRoutes = ({ isAccountAdded }) => (
  <>
    <Switch>
      <Route path={ROUTES?.CALENDAR} component={CalendarWrapper} />
      <Route path={ROUTES?.PROFILE} component={ProfileWrapper} />
      <Route path={ROUTES?.POST} component={PostWrapper} />
      <Route path={ROUTES?.LIBRARY} component={LibraryWrapper} />
      <Route path={`${ROUTES?.ACCOUNTS}/:social`} component={Accounts} />
      <Route path={`${ROUTES?.ACCOUNTS}`} component={AccountsWrapper} />
      <Route
        exact
        path={ROUTES?.MAIN}
        render={() => (
          <Redirect to={isAccountAdded ? ROUTES?.CALENDAR : ROUTES?.ACCOUNTS} />
        )}
      />
      <Route path="*" exact component={Error404} />
    </Switch>
  </>
);

export default ContentRoutes;
